import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Dropdown, TwoButtons } from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import style from "../Form.module.css";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import {
  executeCalculator,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { useNavigate } from "react-router";

const Step1 = ({ handleNext, handleBack }) => {
  const [inputs, setInputs] = useState({
    coverOptions: null,
    options: null,
    children: null,
    additionalChildren: null,
    parent: null,
    parentAbove65: 0,
    parentAbove80: 0,
  });

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const state = useSelector((state) => state.products.riskAttributes);

  const history = useNavigate();

  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "UQQ")?.attributes
  );

  const getAttributes = (value) => {
    return riskAttributes?.find((x) => x.name === value);
  };

  const handleChange = (evt, instanceId) => {
    var arr = [...riskAttributes];
    var index = riskAttributes.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, "UQQ"));
  };

  const [errors, setErrors] = useState({});
  const types = useSelector((state) => state?.products?.types?.types);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      const errorss = validate(
        {
          [getAttributes(ATTRIBUTES.LIVES_COVERED)?.name]:
            getAttributes(ATTRIBUTES.LIVES_COVERED)?.value ?? null,
          [getAttributes(ATTRIBUTES.COVER_OPTIONS)?.name]:
            getAttributes(ATTRIBUTES.COVER_OPTIONS)?.value ?? null,
          [getAttributes(ATTRIBUTES.COVER_AMOUNT)?.name]:
            getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value ?? null,
        },
        {
          [getAttributes(ATTRIBUTES.LIVES_COVERED)?.name]: {
            presence: {
              allowEmpty: false,
            },
          },
          [getAttributes(ATTRIBUTES.COVER_OPTIONS)?.name]: {
            presence: {
              allowEmpty: false,
            },
          },
          [getAttributes(ATTRIBUTES.COVER_AMOUNT)?.name]: {
            presence: {
              allowEmpty: false,
            },
          },
        }
      );

      if (errorss || errorss !== undefined) {
        var errorArray = Object.keys(errorss);
        document.getElementById(errorArray[0]).scrollIntoView({
          behavior: "smooth",
        });
        setErrors(errorss);
        return;
      } else {
        setErrors({});
      }

      var coverOptions = getAttributes(ATTRIBUTES.COVER_OPTIONS)?.value;

      setIsLoading(true);
      const res = await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference: types?.find((x) =>
              coverOptions === "Basic"
                ? x.name === "Basic Cover"
                : x.name === "Comprehensive Cover"
            )?.instanceId,
            risks: [
              {
                attributes: [
                  ...state
                    .find((x) => x.code === RISKS.UQQ)
                    ?.attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value:
                        y.name === ATTRIBUTES.LIVES_COVERED
                          ? y.value === 0
                            ? "Individual"
                            : "Family"
                          : y.name === ATTRIBUTES.COVER_OPTIONS
                          ? y.value === "Basic"
                            ? "Basic Cover"
                            : "Comprehensive Cover"
                          : y.value,
                    })),
                ],
                riskReference: state.find((x) => x.code === RISKS.UQQ)
                  ?.instanceId,
                code: state.find((x) => x.code === RISKS.UQQ)?.code,
              },
            ],
          },
        })
      );
      handleNext();
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const getCoverAmountInt = (amount) => {
    var string = amount;
    string = string?.replace("SZL", "");
    string = string?.replace(",", "");
    return parseInt(string);
  };

  const coverAmount = getCoverAmountInt(
    getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
  );

  useEffect(() => {
    if (!riskAttributes?.length) return;
    var arr = [...riskAttributes];
    const extendedFamily = riskAttributes?.findIndex(
      (x) => x.name === ATTRIBUTES.EXTENDED_FAMILY
    );
    const numberOfExtended = riskAttributes?.findIndex(
      (x) => x.name === ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY
    );
    const parents = riskAttributes?.findIndex(
      (x) => x.name === ATTRIBUTES.PARENTS
    );
    const numberOfParents = riskAttributes?.findIndex(
      (x) => x.name === ATTRIBUTES.NUMBER_OF_PARENTS
    );
    const parentsInLaw = riskAttributes?.findIndex(
      (x) => x.name === ATTRIBUTES.PARENT_IN_LAW
    );
    const numberOfParentsInLaw = riskAttributes?.findIndex(
      (x) => x.name === ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW
    );
    if (coverAmount === 20000) {
      arr[extendedFamily] = { ...arr[extendedFamily], value: null };
      arr[numberOfExtended] = { ...arr[numberOfExtended], value: null };
      dispatch(updatedAttributes(arr, "UQQ"));
    }
    if (coverAmount === 30000) {
      arr[extendedFamily] = { ...arr[extendedFamily], value: null };
      arr[numberOfExtended] = { ...arr[numberOfExtended], value: null };
      arr[parents] = { ...arr[parents], value: null };
      arr[numberOfParents] = { ...arr[numberOfParents], value: null };
      arr[parentsInLaw] = { ...arr[parentsInLaw], value: null };
      arr[numberOfParentsInLaw] = { ...arr[numberOfParentsInLaw], value: null };
      dispatch(updatedAttributes(arr, "UQQ"));
    }
  }, [coverAmount]);

  return state?.length && riskAttributes?.length ? (
    <div className="mt-4 h-100">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Sisekelo Funeral Plan</h3>
            <h2 className="primary-color f-800">Select your cover</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Sisekelo Funeral Plan</p>
          <h2 className="primary-color f-800">Select your cover</h2>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        <TwoButtons
          id="Lives Covered"
          title={"Who do you want to cover?"}
          errors={errors["Lives Covered"]}
          state={getAttributes(ATTRIBUTES.LIVES_COVERED)?.value}
          onClick={(value) => {
            setInputs({ ...inputs, coverOptions: value });
            handleChange(
              value,
              getAttributes(ATTRIBUTES.LIVES_COVERED)?.instanceId
            );
          }}
          options={[
            {
              name: "Just me",
              value: 0,
            },
            {
              name: "Me & family",
              value: 1,
            },
          ]}
        />
        <div style={{ height: "20px" }} />
        <TwoButtons
          id="Cover Level"
          title={"Select your cover option"}
          errors={errors["Cover Option"]}
          state={getAttributes(ATTRIBUTES.COVER_OPTIONS)?.value}
          onClick={(value) => {
            setInputs({ ...inputs, coverOptions: value });
            handleChange(
              value,
              getAttributes(ATTRIBUTES.COVER_OPTIONS)?.instanceId
            );
          }}
          options={[
            {
              name: "Basic",
              value: "Basic",
            },
            {
              name: "Comprehensive",
              value: "Comprehensive",
            },
          ]}
        />
        <div style={{ height: "20px" }} />
        <Dropdown
          half={true}
          customWidth={200}
          id={getAttributes(ATTRIBUTES.COVER_AMOUNT)?.name}
          options={JSON.parse(
            getAttributes(ATTRIBUTES.COVER_AMOUNT)?.settings
          )?.items.map((x) => ({ name: x, value: x }))}
          value={getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value}
          errors={
            Object.keys(errors)
              ? errors[getAttributes(ATTRIBUTES.COVER_AMOUNT)?.name]
              : []
          }
          onClick={(value) =>
            handleChange(
              value,
              getAttributes(ATTRIBUTES.COVER_AMOUNT)?.instanceId
            )
          }
          title={getAttributes(ATTRIBUTES.COVER_AMOUNT)?.description}
        />
      </div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "transparent",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
      <NavigationButtons
        isBackDisabled={isLoading}
        back={() => history("/")}
        loading={isLoading}
        next={handleSubmit}
      />
    </div>
  ) : (
    <Loader />
  );
};

export default Step1;
