// import moment from "moment";

// export const dateFormatter = (date) => {
//     return moment(`${date.slice(7,11)}-${date.slice(4,6)}-${date.slice(1,3)}`).format("YYYY-MM-DD");
// }

import moment from "moment";
import queryString from "query-string";

export const dateFormatter = (date) => {
  return moment(
    `${date.slice(7, 11)}-${date.slice(4, 6)}-${date.slice(1, 3)}`
  ).format("YYYY-MM-DD");
};

export const parseQuery = (queryString) => {
  var query = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
};

// export const setParam = (history,token) => {
//   history({
//     search: `?${queryString.stringify({
//       token: token ? token : (process.env.REACT_APP_ENV_TYPE === 'PROD' || process.env.REACT_APP_ENV_TYPE === 'UAT') ? "libertysz-7" : "LetshegoSZ-1",
//       ...queryString.parse(history.search),
//     })}`,
//     state: history.state,
//   });
// };

export const setParam = (history, token) => {
  const exist = window.location.search
    ?.split("&")
    ?.filter((x) => !x.includes("token"));
  if (exist?.length > 1) {
    localStorage.setItem("redirect_uri", window.location.search);
  }
  const existingQueries = localStorage.getItem("redirect_uri");
  const result = existingQueries
    ?.split("&")
    .filter((str) => !str.includes("token"))
    .join("&");
  if (result) {
    history({
      search: `?${queryString.stringify({
        token: token
          ? token
          : process.env.REACT_APP_ENV_TYPE === "PROD" ||
            process.env.REACT_APP_ENV_TYPE === "UAT"
          ? "libertysz-7"
          : "LetshegoSZ-1",
      })}&${result}`,
      state: history.state,
    });
  } else {
    history({
      search: `?${queryString.stringify({
        token: token
          ? token
          : process.env.REACT_APP_ENV_TYPE === "PROD" ||
            process.env.REACT_APP_ENV_TYPE === "UAT"
          ? "libertysz-7"
          : "LetshegoSZ-1",
        ...queryString.parse(history.search),
      })}`,
      state: history.state,
    });
  }
};
