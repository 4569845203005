import classNames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Summary from "../../../components/Forms/Summary";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import { liberty} from '../../../assets/images/index';


const PaymentSuccess = () => {

    const { width } = useWindowDimensions();
    document.body.style = 'background: #3C409610;';
    const policy = useSelector((state) => state?.prevState?.policy);
    const riskAttributes = useSelector((state) => state?.prevState?.riskAttributes);

    const redirectURL = useSelector((state) => state?.auth?.refererData?.redirectUrl);
    const premiumAmount = useSelector((state) => state?.prevState?.premium);
    const mainMember = useSelector((state) => state?.prevState?.mainMember);

    return (
        <div className="container form-container mt-lg-5 mb-5 p-0">
            <div className="d-flex justify-content-center flex-column">
                {width > 770 ? (
                    <>
                        {/* <------- HEADER --------> */}
                        <h3 className={style.header}>
                            Instant Funeral Cover in a few clicks
                        </h3>
                    </>
                ) : null}



                <div className={classNames("container", style.boxBorder)}>
                    <div className="mt-4 h-100">
                        {width > 770 ? (
                            <>
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <h3 className="primary-color f-700">Sisekelo Funeral Plan</h3>
                                    <h5 className="primary-color f-700">Summary</h5>
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        height: "1px",
                                        backgroundColor: "#00000020",
                                    }}
                                ></div>
                            </>
                        ) : (
                            <div
                                className={classNames(
                                    "d-flex justify-content-start align-items-start flex-column",
                                    style.headerContainer
                                )}
                            >
                                <p className="text-white f-500 m-0 ">Sisekelo Funeral Plan</p>
                                <h5 className="primary-color f-700">Summary</h5>
                            </div>
                        )}
                        <div className='d-flex justify-content-center flex-column align-items-center mt-5 mb-5'>
                            <Summary 
                                title="Sisekelo Funeral Plan"
                                referenceNumber={policy?.policies && policy?.policies[0]?.policyReference}
                                policyHolder={mainMember?.firstName}
                                policyHolderSurname={mainMember?.lastName}
                                policyType={
                                    riskAttributes
                                    ?.find((y) => y.code === RISKS.UQQ)
                                    ?.attributes?.find((x) => x.name === ATTRIBUTES.LIVES_COVERED)
                                    ?.value
                                  }
                                  coverType={
                                    riskAttributes
                                    ?.find((y) => y.code === RISKS.UQQ)
                                    ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_OPTIONS)
                                    ?.value
                                  }
                                premiumAmount={(premiumAmount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                familyPolicy={
                                    riskAttributes
                                      ?.find((y) => y.code === RISKS.UQQ)
                                      ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_AMOUNT)
                                      ?.value ?? 0
                                  }
                                  coverAmount={
                                    riskAttributes
                                      ?.find((y) => y.code === RISKS.UQQ)
                                      ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_AMOUNT)
                                      ?.value ?? 0
                                  }
                            />
                            <div style={{maxWidth: '460px' ,width: '100%'}} className="d-flex justify-content-center flex-column">
                            <img src={liberty} style={{width: '100px',display:"block",marginLeft:"auto",marginRight:"auto"}} />
                            <p style={{fontSize: 13, textAlign: 'center'}}>
                            Underwritten by Liberty Insurance Company Limited (Reg. No. R7/29653), a Licensed Life Insurer and an authorised Financial Services Provider
                            </p>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PaymentSuccess;