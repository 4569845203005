import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";
import {
  DateInput,
  FileUpload,
  Info,
  NumberInput,
  CountryInput,
  TextInput,
  TwoButtons,
} from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import style from "../Form.module.css";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimension";
import classNames from "classnames";
import {
  CreatePolicy,
  policySaleAttachments,
  updateAccordion,
  updateCustomAttributes,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
const moment = require("moment");


const Step2 = ({ handleNext, handleBack, handleFileUpload, files }) => {
  const auth = useSelector((state) => state?.auth?.refererData?.customer);
  const mainMember = useSelector((state) => state?.products?.mainMember);
  const [memberHooks, setMemberHooks] = useState({...mainMember});
  const [authHooks, setAuthHooks] = useState({...auth});
  const [inputs, setInputs] = useState({
    // coverOptions: 0,
    // options: 0,
    // children: 0,
    // additionalChildren: 0,
    // parent: 0,
    // parentAbove65: 0,
    // parentAbove80: 0,
    mobilePhoneCode: mainMember.mobilePhoneCode ?? inputs?.mobilePhoneCode,
    // ...mainMember,
  });

  const childState = useSelector((state) => state.products?.childAttributes);

  const parentState = useSelector((state) => state.products?.parentAttributes);

  const extendedFamilyState = useSelector(
    (state) => state.products?.extendedAttributes ?? []
  );
  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator?.productOptions?.[0]
  );

  const [childAttributes, setChildAttributes] = useState(childState);
  const [childDropdownSupport, setChildDropdownSupport] = useState({});

  const [parentAttributes, setParentAttributes] = useState(parentState);

  const [extendedFamilyAttributes, setextendedFamilytAttributes] =
    useState(extendedFamilyState);

  const [beneCount, setBeneCount] = useState(1);
  const [errors, setErrors] = useState({});
  const policyAttOpt = useSelector((state) => state.products?.policyAttachmentsOptions); 
  const policyAttachments = useSelector( (state) => state?.products?.policyAttachments?.attachments?.[0]);
  const docArr = ["IDD"];
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => {
    updateState({});
  }, []);


  const riskAttributes = useSelector((state) =>
    state.products?.riskAttributes?.map((x) => ({
      ...x,
      attributes: x.attributes.map((y) => ({ ...y, code: x.code })),
    }))
  );

  const riskParentAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "PAR")?.attributes
  );

  const customAttributes = useSelector(
    (state) => state?.products.customAttribues
  );
  const childCoverAttribute = riskAttributes
    .find((z) => z.code === RISKS.UQQ)
    ?.attributes.find((x) => x.name === ATTRIBUTES.CHILD_COVER);
  const parentCoverAttribute = riskAttributes
    .find((z) => z.code === RISKS.UQQ)
    ?.attributes.find((x) => x.name === ATTRIBUTES.PARENTAL_COVER);
  const spouseCoverAttribute = riskAttributes
    .find((z) => z.code === RISKS.UQQ)
    ?.attributes.find((x) => x.name === ATTRIBUTES.SPOUSE_COVER);
  const totalParents = () => {
    var total = riskAttributes
      ?.find((z) => z.code === RISKS.UQQ)
      ?.attributes?.find((x) => x.name === ATTRIBUTES.NUMBER_OF_PARENTS)?.value;
    var total1 = riskAttributes
      ?.find((z) => z.code === RISKS.UQQ)
      ?.attributes?.find(
        (x) => x.name === ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW
      )?.value;
    return parseInt(total) + parseInt(total1 ?? 0);
  };

  const dispatch = useDispatch();
  // const [files, setFiles] = useState([]);

  useEffect(() => {
    if(Object.keys(mainMember).length >= 3){
      let obj = {}
    if (!files?.length) {
    inputs['document_of_proof'] = null
     obj = { ...inputs }
    }
    setInputs(obj);
    dispatch(
      updateCustomAttributes({
        type: "MAIN_MEMBER_POLICY",
        payload: obj,
      })
    );
      setInputs({
        coverOptions: 0,
        options: 0,
        children: 0,
        additionalChildren: 0,
        parent: 0,
        parentAbove65: 0,
        parentAbove80: 0,
        ...mainMember,
        mobilePhoneCode:  mainMember.mobilePhoneCode ?? inputs?.mobilePhoneCode,
      });
    }else{
      let obj = {}
    if (!files?.length) {
    inputs['document_of_proof'] = null
     obj = { ...inputs }
    }
    setInputs(obj);
    dispatch(
      updateCustomAttributes({
        type: "MAIN_MEMBER_POLICY",
        payload: obj,
      })
    );
      setInputs({
        coverOptions: 0,
        options: 0,
        children: 0,
        additionalChildren: 0,
        parent: 0,
        parentAbove65: 0,
        parentAbove80: 0,
        mobilePhoneCode: "+268",
        // mobilePhoneCode: mainMember.mobilePhoneCode ?? inputs?.mobilePhoneCode ?? "+268",
        dob: moment(authHooks?.birthDate).format("DD-MM-YYYY"), 
        firstName : authHooks?.firstName ,
        lastName : authHooks?.lastName,
        eMail: authHooks?.eMail ,
        mobilePhone:  authHooks?.mobilePhone ,
        address: authHooks?.address,
        address2:authHooks?.address2 ,
      });
    }
  }, []);

  useEffect(() => {
    if (errors) {
      console.log(errors);
    }
  }, [errors]);

 
  // const handleFileUpload = (val) => {
  //   if (val.target.files[0].size / 1000000 > 2) {
  //     setFiles([""]);
  //     forceUpdate();
  //     return toast.error("Please upload file size less than 2mb");
  //   }
  //   setFiles(val.target.files);
  //    let obj = { ...inputs }
  //     obj = {
  //       ...inputs,
  //       ['document_of_proof']:"yes",
  //     };
  //   setInputs(obj)

  //   dispatch(
  //     updateCustomAttributes({
  //       type: "MAIN_MEMBER_POLICY",
  //       payload: obj,
  //     })
  //   );
  // };

  const handleChange = (evt, instanceId, code) => {
    var attribute = riskAttributes.find((x) => x.code === code)?.attributes;
    var arr = [...attribute];
    var index = attribute.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, code));
  };

  const getCoverAmountInt = (amount) => {
    var string = amount;
    string = string?.replace('SZL', '');
    string = string?.replace(',', '')
    return parseInt(string);
  }

  // useEffect(() => {
  //   let obj = {};
  //   inputs['mobilePhoneCode'] = `+${"268"}`
  //    obj = { ...inputs }
  //   setInputs(obj);
  //   dispatch(updateAccordion({
  //     type: "MAIN_MEMBER_POLICY",
  //     payload: obj,
  //   }))
  // },[])
 
  const handleChangePolicy = (e, name) => {
    let obj = { ...inputs }
    if (name === "mobilePhoneCode") {
      obj = {
        ...inputs,
        [name]: `+${e}`,
      };
    } else { 
      obj = {
        ...inputs,
        [e.target.name]: e.target.value,
      };
    }
    setInputs(obj)
    dispatch(
      updateCustomAttributes({
        type: "MAIN_MEMBER_POLICY",
        payload: obj,
      })
    );
  };

  const handleCustomChange = (evt, name, index) => {
    var arr = [...childAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setChildAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "CHILD_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const handleCustomParentChange = (evt, name, index) => {
    var arr = [...parentAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setParentAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "PARENT_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const handleCustomExtendedChange = (evt, name, index) => {
    if(extendedFamilyAttributes.length){
      var arr = [...extendedFamilyAttributes];
      arr[index] = { ...arr[index], [name]: evt };
      setextendedFamilytAttributes(arr);
      dispatch(
        updateCustomAttributes({
          type: "EXTENDED_FAMILY_ATTRIBUTES",
          payload: arr,
        })
      );
    }else{
      var arr = [];
      arr[index] = { ...arr[index], [name]: evt };
      setextendedFamilytAttributes(arr);
      dispatch(
        updateCustomAttributes({
          type: "EXTENDED_FAMILY_ATTRIBUTES",
          payload: arr,
        })
      );
    }
  };

  const calculateMinDate = (type) => {
    const date = new Date();
    if (type === "parent") {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === undefined) {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "extended") {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "bene") {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }

    if (type === "child") {
      date.setFullYear(date.getFullYear() - 24);
    } else {
      date.setFullYear(date.getFullYear() - 80);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateBeneMinDate = (type) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 36);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateMaxDate = (type) => {
    const date = new Date();
    if (type === "parent") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === undefined) {
      // Spouse -> undefined
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "extended") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "bene") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }

    if (type === "child") {
      return new Date();
    } else {
      date.setFullYear(date.getFullYear() - 18);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateBeneMaxDate = (type) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const renderComponents = useCallback(
    (item, custom, index) => {
      switch (item.dataType) {
        case 1:
          var maxLength = null;
          if (
            item.instanceId === "25c8e9ac-d9a9-46b8-aa95-65fe6eac1e7c" ||
            item.instanceId === "f063dfab-9776-4c14-9808-24670fd9b764" ||
            item.instanceId === "d3b66b0a-9bca-4589-a294-b1b496d62bd1" ||
            item.instanceId === "9f127ffe-2f62-403d-88c1-e59789034d9c" ||
            item.instanceId === "1edb5d30-58f0-4b7e-93c1-b937bbd7d5f5" ||
            item.instanceId === "d20babc6-c6a9-404a-af3b-ebc8c2ce08f8" ||
            item.instanceId === "1316453a-2fb9-48a7-9dcc-eb5eb7cfae37" ||
            item.instanceId === "14117f05-0052-4f4b-a9f8-9aabd3edff1c" ||
            item.instanceId === "77549fdb-a33e-4f89-a5fb-d0bd4189e727" ||
            item.instanceId === "fd2cc3ca-2697-46ab-94b0-4c0d2f164d33" ||
            item.instanceId === "2735593e-ad13-4dca-f050-0f316f2a5282" ||
            item.instanceId === "02f47a1f-b411-5e80-ebfa-b3c94e84a6b6"
          ) {
            maxLength = 30;
          }
          return (
            <TextInput
              id={item.name}
              label={item.description}
              errors={
                custom ? errors[`${item.name} ${index + 1}`] : errors[item.name]
              }
              name={item.name}
              maxLength={maxLength}
              value={
                custom === "child"
                  ? childState?.[index]
                    ? childState?.[index]?.[item?.name]
                    : null
                  : custom === "parent"
                    ? parentState?.[index]
                      ? parentState?.[index]?.[item?.name]
                      : null
                    : custom === "extended"
                      ? extendedFamilyState?.[index]
                        ? extendedFamilyState?.[index]?.[item?.name]
                        : null
                      : custom === "bene"
                        ? beneficiaries?.[index]
                          ? beneficiaries?.[index]?.[item?.name]
                          : null
                        : item?.value
              }
              onChange={(e) =>
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "parent"
                    ? handleCustomParentChange(e.target.value, item.name, index)
                    : custom === "extended"
                      ? handleCustomExtendedChange(e.target.value, item.name, index)
                      : custom === "bene"
                        ? handleChangeBene(e, index)
                        : handleChange(e.target.value, item.instanceId, item.code) ||
                        handleChangePolicy(e, item.instanceId, item.code)
              }
              required={item.isRequired}
            />
          );
        case 2:
          return (
            <NumberInput
              id={item.name}
              label={item.description}
              errors={custom ? "" : errors[item.name]}
              name={item.name}
              placeholder={"+268123456789"}
              value={
                custom === "child"
                  ? childState?.[index]
                    ? childState?.[index]?.[item?.name]
                    : null
                  : custom === "parent"
                    ? parentState?.[index]
                      ? parentState[index]?.[item?.name]
                      : null
                    : custom === "extended"
                      ? extendedFamilyState?.[index]
                        ? extendedFamilyState?.[index]?.[item?.name]
                        : null
                      : item?.value
              }
              onChange={(e) =>
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "parent"
                    ? handleCustomParentChange(e.target.value, item.name, index)
                    : custom === "extended"
                      ? handleCustomExtendedChange(e.target.value, item.name, index)
                      : handleChange(e.target.value, item.instanceId, item.code)
              }
              required={item.isRequired}
            />
          );
        case 5:
          return (
            <DateInput
              id={item.name}
              label={item.description}
              errors={
                custom ? errors[`${item.name} ${index + 1}`] : errors[item.name]
              }
              name={item.name}
              minDate={calculateMinDate(custom)}
              maxDate={calculateMaxDate(custom)}
              value={
                custom === "child"
                  ? childState?.[index]
                    ? childState?.[index]?.[item?.name]
                    : null
                  : custom === "parent"
                    ? parentState?.[index]
                      ? parentState?.[index]?.[item?.name]
                      : null
                    : custom === "extended"
                      ? extendedFamilyState?.[index]
                        ? extendedFamilyState?.[index]?.[item?.name]
                        : null
                      : custom === "bene"
                        ? beneficiariesState?.[index]
                          ? beneficiariesState?.[index]?.[item?.name]
                          : null
                        : item?.value
              }
              onChange={(e) =>
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "parent"
                    ? handleCustomParentChange(e.target.value, item.name, index)
                    : custom === "extended"
                      ? handleCustomExtendedChange(e.target.value, item.name, index)
                      : custom === "bene"
                        ? handleChangeBene(e, index)
                        : handleChange(e.target.value, item.instanceId, item.code)
              }
              required={item.isRequired}
            />
          );
        case 7:
          if (
            item.settings.includes('["Yes","No"]') ||
            item.settings.includes('["NO","YES"]') ||
            item.settings.includes('["Female","Male"]') ||
            item.settings.includes('["Male","Female"]')
          ) {
            return <></>;
          }
          return (
            <div className="d-flex flex-column mt-2">
              <label className="primary-color f-700 mt-1">
                {item.description}
                {item?.isRequired ? "*" : null}
              </label>
              <ReactSelect
                id={item.name}
                placeholder={''}
                value={custom === "child"
                  ? childState[index]
                    ? { label: childState?.[index]?.[item?.name], value: childState?.[index]?.[item?.name] }
                    : null
                  : custom === "parent"
                    ? parentState[index]
                      ? { label: parentState?.[index]?.[item?.name], value: parentState?.[index]?.[item?.name] }
                      : null
                    : custom === "extended"
                      ? extendedFamilyState?.[index]
                        ? { label: extendedFamilyState?.[index]?.[item?.name], value: extendedFamilyState?.[index]?.[item?.name] }
                        : null
                      : custom === "bene"
                        ? beneficiariesState?.[index]
                          ? { label: beneficiariesState?.[index]?.[item?.name], value: beneficiariesState?.[index]?.[item?.name] }
                          : null
                        : { label: item?.value, value: item?.value }}
                options={JSON.parse(item.settings)?.items?.map((x) => ({
                  label: x,
                  value: x,
                }))}
                defaultValue={{ label: JSON.parse(item.settings)?.items?.[0], value: JSON.parse(item.settings)?.items?.[0] }}
                onChange={(e) =>
                  custom === "child"
                    ? handleCustomChange(e.value, item.name, index)
                    : custom === "parent"
                      ? handleCustomParentChange(e.value, item.name, index)
                      : custom === "extended"
                        ? handleCustomExtendedChange(e.value, item.name, index)
                        : custom === "bene"
                          ? handleChangeBene(e.value, index)
                          : handleChange(e.value, item.instanceId, item.code)
                }
              />
              {(custom ? errors[`${item.name} ${index + 1}`] : errors[item.name])?.length && (
                <span className="text-danger errors" style={{ marginLeft: 0 }}>
                  {(custom ? errors[`${item.name} ${index + 1}`] : errors[item.name]) && (custom ? errors[`${item.name} ${index + 1}`] : errors[item.name])[0]}
                </span>
              )}
            </div>
          );
      }
    },
    [errors, riskAttributes]
  );
  const { width } = useWindowDimensions();

  const accordionState = useSelector((state) => state?.products?.accordion);

  const [expand, setExpand] = useState({
    ...accordionState,
  });

  const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);

  const option = useSelector((state) =>
    state.products?.riskAttributes
      .find((x) => x.code === RISKS.UQQ)
      ?.attributes.find((y) => y.name === ATTRIBUTES.LIVES_COVERED)
  );

  const quqRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "UQQ")?.attributes
  );

  const manRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "MAN")?.attributes
  );

  const benRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "BEN")?.attributes
  );

  const premiumAmount = useSelector((state) => state?.products?.premium);

  const getAttributes = (value) => {
    return quqRiskAttributes.find((x) => x.name === value);
  };

  const getManAttributes = (value) => {
    return manRiskAttributes.find((x) => x.name === value);
  };

  const getBenAttributes = (value) => {
    return benRiskAttributes.find((x) => x.name === value);
  };

  const reducerState = useSelector((state) => state?.products);

  const getRows = (rows) => {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const [isLoading, setIsLoading] = useState(false);

  const getConstraints = () => {
    var val = {};

    // var attributes_name = [];
    // attributes_name = [{ name: "document_of_proof" }]
  //   if (mainMember?.document_of_proof !== "yes") {
  //   val = {
  //     ...val,
  //     "document_of_proof": {
  //       presence: {
  //         allowEmpty: false,
  //       },
  //     },
  //   };
  // }

    if (option?.value === 1) {
      if (customAttribues?.spouse_cover === 0) {
        riskAttributes
          .find((x) => x.code === "SPO")
          ?.attributes.forEach((y, i) => {
            if (y.isRequired && y.name !== "Spouse Gender") {
              console.log('-----test')
              val = {
                ...val,
                ["Spouse First Name"]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z /-]*$/g,
                    message: "can only contain alphabets, hyphen and spaces",
                  },
                },
                ["Spouse Last Name"]: {
                  presence: {
                    allowEmpty: false,
                  },
                  format: {
                    pattern: /^[A-Za-z /-]*$/g,
                    message: "can only contain alphabets, hyphen and spaces",
                  },
                },
                [y.name]: {
                  presence: {
                    allowEmpty: false,
                  },
                },
              };
            }
          });
      }

      if (
        riskAttributes
          ?.find((x) => x.code === RISKS.UQQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.PARENTS)?.value ===
        0 ||
        riskAttributes
          ?.find((x) => x.code === RISKS.UQQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.PARENT_IN_LAW)
          ?.value === 0
      ) {
        getRows(totalParents()).map((x, i) => {
          val = {
            ...val,
            [`Parent First Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "can only contain alphabets",
              },
            },
            [`Parent Last Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "can only contain alphabets",
              },
            },
            [`Parent DOB ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
            [`Parent Relationship to Main Member ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        });
      }
      if (
        riskAttributes
          ?.find((x) => x.code === RISKS.UQQ)
          ?.attributes?.find((z) => z.name === ATTRIBUTES.EXTENDED_FAMILY)
          ?.value === 0
      ) {
        getRows(
          parseInt(getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)?.value)
        ).map((x, i) => {
          val = {
            ...val,
            [`Extended Family First Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "can only contain alphabets",
              },
            },
            [`Extended Family Last Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "can only contain alphabets",
              },
            },
            [`Extended Family DOB ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
            [`Extended Fam Relationship to Main Member ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
            // [`Extended Family Gender ${i + 1}`]: {
            //   presence: {
            //     allowEmpty: false,
            //   },
            // },
          };
        });
      }

      if (customAttribues?.child_cover === 0) {
        getRows(customAttribues?.numberOfChildren).map((x, i) => {
          val = {
            ...val,
            [`Child First Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "can only contain alphabets",
              },
            },
            [`Child Last Name ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
              format: {
                pattern: /^[A-Za-z]+$/,
                message: "can only contain alphabets",
              },
            },
            [`Child DOB ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
            [`Child Relationship to Main Member ${i + 1}`]: {
              presence: {
                allowEmpty: false,
              },
            },
            // [`Child Gender ${i + 1}`]: {
            //   presence: {
            //     allowEmpty: false,
            //   },
            // },
          };
        });
      }
    }

    if (beneficiaries?.length) {
      // getRows(beneficiaries?.length).map((x, i) => {
        getRows(beneCount)?.map((x, i) => {
        val = {
          ...val,
          [`Beneficiary First Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z]+$/,
              message: "can only contain alphabets",
            },
          },
          [`Beneficiary Last Name ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            format: {
              pattern: /^[A-Za-z]+$/,
              message: "can only contain alphabets",
            },
          },
          [`Email ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
            email: true,
          },
          [`Phone Number ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: `^Mobile number ${i + 1} can't be blank`,
            },
            format: {
              pattern: /^(\+\d{1,3}[- ]?)?\d{8,14}$/,
              message: "^ Invalid mobile number",
            },
          },
          [`Telephone number ${i + 1}`]: {
            // presence: {
            //   allowEmpty: false,
            // },
            format: {
              pattern: /^(\+\d{1,3}[- ]?)?\d{8,14}$/,
              message: "^ Invalid mobile number",
            },
          },
          [`Beneficiary ID Number ${i + 1}`]: {
            format: {
              pattern: /^[0-9]+$/,
              message: "can only contain numbers",
            },
          },
          [`Beneficiary Country of Birth ${i + 1}`]: {
            format: {
              pattern: /^[a-zA-Z\s]*$/,
              message: "can only contain alphabets",
            },
          },

          [`Gender ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
          },
          [`Beneficiary Date of Birth ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
          },
          [`Percentage ${i + 1}`]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
      });
    }

    delete val["P.O. Box"];
    return { ...val, ...schema };
  };

  // useEffect(() => {
  //   let obj = {}
  //   if (!files?.length) {
  //   inputs['document_of_proof'] = null
  //    obj = { ...inputs }
  //   }
  //   setInputs(obj);
  //   dispatch(
  //     updateCustomAttributes({
  //       type: "MAIN_MEMBER_POLICY",
  //       payload: obj,
  //     })
  //   );
  // }, []);


  var getAttr = function () {
    let attrVal = [];
    Object.entries(inputs).forEach(([key, value]) => {
      if (key === "mainGender" || key === "maritalStatus") {
        attrVal.push({ name: key, value: value.value });
      } else {
        attrVal.push({ name: key, value: value });
      }
    });
    return attrVal;
  };

  const handleSubmit = async () => {
    var values = {
      ...inputs,
      maritalStatus: inputs.maritalStatus?.value,
      mainGender: inputs?.mainGender?.value,
    };
    riskAttributes
      ?.find((x) => x.code === "SPO")
      ?.attributes?.forEach((t) => {
        values = { ...values, [t.name]: t.value };
      });

    riskAttributes
      ?.find((x) => x.code === "CHI")
      ?.attributes?.forEach((t) => {
        values = { ...values, [t.name]: t.value };
      });

    getRows(totalParents()).map((x, i) => {
      values = {
        ...values,
        [`Parent First Name ${i + 1}`]:
          parentState[i]?.[`Parent First Name`],
        [`Parent Last Name ${i + 1}`]:
          parentState[i]?.[`Parent Last Name`],
        [`Parent DOB ${i + 1}`]: parentState[i]?.[`Parent DOB`],
        [`Parent Relationship to Main Member ${i + 1}`]:
          parentState[i]?.[`Parent Relationship to Main Member`],
        [`Parent Gender ${i + 1}`]: parentState[i]?.[`Parent Gender`],
      };
    });

    getRows(customAttributes?.numberOfChildren).map((x, i) => {
      values = {
        ...values,
        [`Child First Name ${i + 1}`]: childState[i]?.[`Child First Name`],
        [`Child Last Name ${i + 1}`]: childState[i]?.[`Child Last Name`],
        [`Child Relationship to Main Member ${i + 1}`]: childState[i]?.[`Child Relationship to Main Member`],
        [`Child DOB ${i + 1}`]: childState[i]?.[`Child DOB`],
        [`Child Gender ${i + 1}`]: childState[i]?.[`Child Gender`]
      };
    });


    getRows(getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)?.value).map(
      (x, i) => {
        values = {
          ...values,
          [`Extended Family First Name ${i + 1}`]:
            extendedFamilyState[i]?.[`Extended Family First Name`],
          [`Extended Family Last Name ${i + 1}`]:
            extendedFamilyState[i]?.[`Extended Family Last Name`],
          [`Extended Family DOB ${i + 1}`]:
            extendedFamilyState[i]?.[`Extended Family DOB`],
          [`Extended Fam Relationship to Main Member ${i + 1}`]:
            extendedFamilyState[i]?.[
            `Extended Fam Relationship to Main Member`
            ],
          [`Extended Family Gender ${i + 1}`]:
            extendedFamilyState[i]?.[`Extended Family Gender`],
        };
      }
    );

    let beneAttributes = [];

    if (beneficiaries?.length) {
        getRows(beneCount)?.forEach((x, i) => {
        beneAttributes.push(`Beneficiary First Name ${i + 1}`);
        beneAttributes.push(`Last Name ${i + 1}`);
        beneAttributes.push(`Email ${i + 1}`);
        beneAttributes.push(`Percentage ${i + 1}`);
        values = {
          ...values,
          [`Beneficiary First Name ${i + 1}`]: Object.keys(
            beneficiaries[i] ?? {}
          )?.length
            ? beneficiaries[i]?.["Beneficiary First Name"]
            : null,
          [`Beneficiary Last Name ${i + 1}`]: Object.keys(
            beneficiaries[i] ?? {}
          )?.length
            ? beneficiaries[i]?.["Beneficiary Last Name"]
            : null,
          [`Email ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})?.length
            ? beneficiaries[i]["email"]
            : null,
          [`Phone Number ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})?.length
            ? beneficiaries[i]["number"]
            : null,
          [`Percentage ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})?.length
            ? beneficiaries[i]["percentage"]
            : null,
          [`Telephone number ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
            ?.length
            ? beneficiaries[i]["telephoneNumber"]
            : null,
            [`Beneficiary ID Number ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
            ?.length
            ? beneficiaries[i][`Beneficiary ID Number`]
            : null,  
            [`Beneficiary Country of Birth ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
            ?.length
            ? beneficiaries[i][`Beneficiary Country of Birth`]
            : null,    
          [`Beneficiary Date of Birth ${i + 1}`]: Object.keys(
            beneficiaries[i] ?? {}
          )?.length
            ? beneficiaries[i]?.["Beneficiary Date of Birth"]
            : null,
          [`Postal Address ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})
            ?.length
            ? beneficiaries[i]["postalAddress"]
            : null,
          [`Gender ${i + 1}`]: Object.keys(beneficiaries[i] ?? {})?.length
            ? beneficiaries[i]["gender"]
            : null,
        };
      });
    }

    const errors = validate(values, {...schema, ...getConstraints() });
    let err = { ...errors }; 

    if (!files?.document_of_proof) {
      err = {
        ...err,
        document_of_proof: ['Identification document is required']
      }
    } else {
      delete err?.document_of_proof
    }

    if (err || err !== undefined) {
      Object.keys(err)?.map((x) => {
        if (Object.keys(schema)?.includes(x) || x === "document_of_proof") {
          err = {
            ...err,
            inputState: ["Please fill policy holder details "],
          };
        }
      });
    }
    
    if (err || err !== undefined) {
      if (beneficiaries?.length) {
        Object.keys(err)?.map((x) => {
          if (beneAttributes?.includes(x)) {
            err = {
              ...err,
              beneficiaries: ["Please fill beneficiaries details "],
            };
          }
        });
      }
    }

    if (err || err !== undefined) {
      if (customAttribues?.spouse_cover === 0) {
        Object.keys(err)?.map((x) => {
          if (x.indexOf("Spouse") === 0) {
            err = {
              ...err,
              spouseState: ["Please fill spouse details "],
            };
          }
        });
      }
    }

    if (err || err !== undefined) {
      if (customAttribues?.child_cover === 0) {
        Object.keys(err)?.map((x) => {
          if (x.indexOf("Child") === 0) {
            err = {
              ...err,
              childState: ["Please fill child details "],
            };
          }
        });
      }
    }

    if (err || err !== undefined) {
      if (riskAttributes
        ?.find((x) => x.code === RISKS.UQQ)
        ?.attributes?.find((z) => z.name === ATTRIBUTES.PARENTS)?.value ===
      0 ||
      riskAttributes
        ?.find((x) => x.code === RISKS.UQQ)
        ?.attributes?.find((z) => z.name === ATTRIBUTES.PARENT_IN_LAW)
        ?.value === 0) {
        Object.keys(err)?.map((x) => {
          if (x.indexOf("Parent") === 0) {
            err = {
              ...err,
              parentState: ["Please fill parent details "],
            };
          }
        });
      }
    }


    if (err || err !== undefined) {
      if (riskAttributes
        ?.find((x) => x.code === RISKS.UQQ)
        ?.attributes?.find((z) => z.name === ATTRIBUTES.EXTENDED_FAMILY)?.value === 0) {
        Object.keys(err)?.map((x) => {
          if (x.indexOf("Extended") === 0) {
            err = {
              ...err,
              extendedState: ["Please fill extended details "],
            };
          }
        });
      }
    }

    if (err || err !== undefined) {
      if (inputs.incomeTax) {
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if(format.test(inputs.incomeTax)){
          Object.keys(err)?.map((x) => {
           err = {
          ...err,
          incomeTax: ["Income tax can only contain alphabets and numbers"],
        };
    });
        }
    }
  }

  if (err || err !== undefined) {
    if (inputs.telePhone) {
      var format = /^(\+\d{1,3}[- ]?)?\d{8,14}$/
      if(!format.test(inputs.telePhone)){
        Object.keys(err)?.map((x) => {
         err = {
        ...err,
        telePhone: ["Invalid Telephone number"],
      };
    });
      }
    }
  }


  if (err || err !== undefined) {
    if (inputs[getManAttributes(ATTRIBUTES.OCCUPATION_INDUSTRY)?.name]) {
      var format = /^[a-zA-Z\s]*$/
      if(!format.test(inputs[getManAttributes(ATTRIBUTES.OCCUPATION_INDUSTRY)?.name])){
        Object.keys(err)?.map((x) => {
         err = {
        ...err,
        'Main Member Occupation Industry': ["Occupation Industry can only contain alphabets"],
      };
    });
      }
    }
  }

  if (err || err !== undefined) {
    if (inputs[getManAttributes(ATTRIBUTES.OCCUPATION)?.name]) {
      var format = /^[a-zA-Z\s]*$/
      if(!format.test(inputs[getManAttributes(ATTRIBUTES.OCCUPATION)?.name])){
        Object.keys(err)?.map((x) => {
         err = {
        ...err,
        'Main member occupation': ["Occupation can only contain alphabets"],
      };
    });
      }
    }
  }

  if (err || err !== undefined) {
    if (inputs[getManAttributes(ATTRIBUTES.COUNTRY_BIRTH)?.name]) {
      var format = /^[a-zA-Z\s]*$/
      if(!format.test(inputs[getManAttributes(ATTRIBUTES.COUNTRY_BIRTH)?.name])){
        Object.keys(err)?.map((x) => {
         err = {
        ...err,
        'Main Member Country of Birth': ["Country of Birth can only contain alphabets"],
      };
    });
      }
    }
  }

  if (err || err !== undefined) {
    if (inputs[getManAttributes(ATTRIBUTES.CITIZENSHIP)?.name]) {
      var format = /^[a-zA-Z\s]*$/
      if(!format.test(inputs[getManAttributes(ATTRIBUTES.CITIZENSHIP)?.name])){
        Object.keys(err)?.map((x) => {
         err = {
        ...err,
        'Main Member Citizenship': ["Citizenship can only contain alphabets"],
      };
    });
      }
    }
  }

    if (err || err !== undefined) {
       setErrors(err);
    }else{ 
      setErrors({})  
    }

    if (Object.keys(err)?.length !== 0) {
      setErrors(err);
      return;
    } else {
      setErrors({}); 
    }

    const beneCountPercent = beneficiaries.reduce((a, b) => a + b.percentage, 0);

    if (beneCountPercent !== 100) {
      return setErrors({
        ...err,
        ["beneficiariesSplit"]: [
          `Benefit split for all beneficiaries must be total to 100%`,
        ],
      });
    }

    var parentsArray = ['Father','Father-In-Law'];
    var extendedArray =['Grand-father','Uncle','Brother','Brother-in-Law','Nephew'];
    var policyRisks =
      option?.value === 0
        ? [
          {
            externalReference: uuidv4(),
            attributes: [
              ...reducerState.riskAttributes
                .find((x) => x.code === "UQQ")
                ?.attributes.map((y) => ({
                  ...y,
                  value:
                    y.name === ATTRIBUTES.LIVES_COVERED
                      ? option?.value === 0
                        ? "Individual"
                        : "Family"
                      : y.name === "Number of Immediate Family Members"
                        ? (customAttributes?.numberOfChildren ?? 0) +
                        (customAttributes?.spouse_cover === 0 ? 1 : 0)
                        : y.name === "Immediate family"
                          ? customAttribues?.spouse_cover === 0 ||
                            customAttributes?.child_cover === 0
                            ? 1
                            : 0
                          : y.value,
                })),
            ],
            valuations:
              reducerState?.executeCalculator?.productOptions?.[0]?.valuations
                .values,
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "UQQ"
            )?.instanceId,
            price: premiumAmount,
            discount: 0,
            tax: 0,
            total: premiumAmount,
          },
          ...beneficiaries.map((x, i) => ({
            attributes: Object.keys(x).map((y) => ({ name: y, value: x[y] })),
            externalReference: uuidv4(),
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "BEN"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          })),

          {
            attributes: getAttr(),
            externalReference: uuidv4(),
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "MAN"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          },
        ]
        : [
          {
            externalReference: uuidv4(),
            attributes: [
              ...reducerState.riskAttributes
                .find((x) => x.code === "UQQ")
                ?.attributes.map((y) => ({
                  ...y,
                  value:
                    y.name === ATTRIBUTES.LIVES_COVERED
                      ? option?.value === 0
                        ? "Individual"
                        : "Family"
                      : y.name === ATTRIBUTES.PARENTS
                        ? y.value === 0
                          ? "Yes"
                          : "No"
                        : y.name === ATTRIBUTES.PARENT_IN_LAW
                          ? y.value === 0
                            ? "Yes"
                            : "No"
                          : y.name === ATTRIBUTES.EXTENDED_FAMILY
                            ? y.value === 0
                              ? "Yes"
                              : "No"
                            : y.name === ATTRIBUTES.IMMEDIATE_FAMILY
                              ? y.value === 0
                                ? "Yes"
                                : "No"
                              : y.name === "Number of Immediate Family Members"
                                ? (customAttributes?.numberOfChildren ?? 0)
                                : y.name === "Immediate family"
                                  ?
                                  customAttributes?.child_cover === 0
                                    ? 'Yes'
                                    : 'No'
                                  : y.name === 'Children'
                                    ? (customAttributes?.child_cover === 0 ? 'Yes' : 'No')
                                    : y.name === 'Number of Children'
                                      ? (customAttributes?.numberOfChildren ?? 0)
                                      : y.value,
                })),
            ],
            valuations:
              reducerState?.executeCalculator?.productOptions?.[0]?.valuations
                .values,
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "UQQ"
            )?.instanceId,
            price: premiumAmount,
            discount: 0,

            tax: 0,
            total: premiumAmount,
          },
          {
            externalReference: uuidv4(),
            attributes: [
              ...reducerState.riskAttributes
                .find((x) => x.code === "SPO")
                ?.attributes.map((i) => ({
                  ...i,
                  value: i.name.toLowerCase().includes('gender') ? reducerState.riskAttributes
                    .find((x) => x.code === "SPO")
                    ?.attributes?.find((q) => q.name.toLowerCase().includes('relationship'))?.value === 'Husband' ? "Male" : "Female" : i.value,
                })),
            ],  
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "SPO"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          },
          ...childAttributes.map((x, i) => ({
            attributes: [...Object.keys(x).map((y) => ({
              name: y,
              value: x[y],
            })), {
              name: reducerState.riskAttributes.find(
                (x) => x.code === "CHI"
              )?.attributes.find((g) => g.name.toLowerCase().includes('gender'))?.name,
              value: x['Child Relationship to Main Member'] === 'Son' ? 'Male' : 'Female'
            }],  
            externalReference: uuidv4(),
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "CHI"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          })),
          ...parentAttributes?.map((x, i) => ({
            attributes: [...Object?.keys(x)?.map((y) => ({ name: y, value: x[y] })), {
              name: reducerState.riskAttributes.find(
                (x) => x.code === "PAR"
              )?.attributes.find((g) => g.name.toLowerCase().includes('gender'))?.name,
              value: parentsArray.includes(x?.['Parent Relationship to Main Member'])  === true ? 'Male' : 'Female'
            }],
            externalReference: uuidv4(),
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "PAR"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          })),
          ...extendedFamilyAttributes?.map((x, i) => ({
            attributes: [...Object?.keys(x)?.map((y) => ({ name: y, value: x[y] })), {
              name: reducerState.riskAttributes.find(
                (x) => x.code === "EXF"
              )?.attributes.find((g) => g.name.toLowerCase().includes('gender'))?.name,
              value: extendedArray.includes(x?.['Extended Fam Relationship to Main Member'])  === true ? 'Male' : 'Female'
            }],
            externalReference: uuidv4(),
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "EXF"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          })),
          ...beneficiaries.map((x, i) => ({
            attributes: Object.keys(x).map((y) => ({ name: y, value: x[y] })),
            externalReference: uuidv4(),
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "BEN"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          })),

          {
            attributes: [
              ...reducerState.riskAttributes
                .find((x) => x.code === "MAN")
                ?.attributes.map((i) => ({
                  ...i,
                  value: i.name === 'Main Member Other Liberty Policies' ? inputs[getManAttributes(ATTRIBUTES.LIBERTY_POLICIES)?.name] :
                    i.value,
                })),
            ],
            externalReference: uuidv4(),
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "MAN"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          },
        ];

    setIsLoading(true);
    try {
      // setIsLoading(true);
      const res = await dispatch(
        CreatePolicy({
          policies: [
            {
              startDate: null,
              endDate: null,
              PolicyStatus: "Quoted",
              currencyCode: "SZL",
              members: [
                {
                  firstName: inputs?.firstName,
                  lastName: inputs?.lastName,
                  birthDate: inputs?.dob,
                  mobilePhone: inputs?.mobilePhone,
                  mobilePhoneCode: inputs?.mobilePhoneCode,
                  nationalityId: inputs?.nationalityId,
                  nationality: inputs?.nationality,
                  VATNumber: inputs?.incomeTax,
                  MarriageStatus: inputs?.maritalStatus?.value,
                  gender: inputs?.mainGender?.value,
                  LandlinePhone: inputs?.telePhone,
                  Address: inputs?.physicalAddress,
                  postalAddress: inputs?.postalAddress,
                  workGPSCoordinate: inputs?.occupation,
                  occupationIndustry:
                    inputs[
                      getManAttributes(ATTRIBUTES.OCCUPATION_INDUSTRY)?.name
                    ],
                  occupation:
                    inputs[getManAttributes(ATTRIBUTES.OCCUPATION)?.name],
                  country:
                    inputs[getManAttributes(ATTRIBUTES.COUNTRY_BIRTH)?.name],
                  citizenship:
                    inputs[getManAttributes(ATTRIBUTES.CITIZENSHIP)?.name],
                  liberty_Policies:
                    inputs[getManAttributes(ATTRIBUTES.LIBERTY_POLICIES)?.name],
                  eMail: inputs?.eMail,
                  memberType: 1,
                  relationship: 0,
                },
              ],

              beneficiaries: [
                ...beneficiaries.map((x) => ({
                  firstName:
                    x[getBenAttributes(ATTRIBUTES.BEN_FIRSTNAME)?.name],
                  lastName: x[getBenAttributes(ATTRIBUTES.BEN_LASTNAME)?.name],
                  country: x[getBenAttributes(ATTRIBUTES.BEN_COUNTRY)?.name],
                  dob: x[getBenAttributes(ATTRIBUTES.BEN_DOB)?.name],
                  nationalityId:
                    x[getBenAttributes(ATTRIBUTES.BEN_ID_NUM)?.name],
                  eMail: x.email,
                  relationship:
                    x.relationship && x.relationship.includes("-")
                      ? x.relationship.split("-").join("").toLowerCase()
                      : x.relationship,
                  telephoneNumber: x.telephoneNumber,
                  postalAddress: x.postalAddress,
                  gender: x.gender,
                  mobilePhone: x.number,
                  split: x.percentage / 100,
                })),
              ],
              attributes: [],
              risks: policyRisks,
              bankAccounts: null,
              productOptionReference:
                reducerState?.executeCalculator?.productOptions?.[0]
                  ?.productOptionReference,
              status: 15,
            },
          ],
          networkId:
            process.env.REACT_APP_ENV_TYPE === "DEV"
              ? "6c41e157-0764-80a5-88bb-5e8db0efa1c6"
              : process.env.REACT_APP_ENV_TYPE === "PROD"
              ? " 5ca47bc0-4bbf-f87d-0d5a-877b62ebf670"
              : agentOnBoarding?.network?.instanceId, // NetworkId from agentOnboarding
          companyId: agentOnBoarding?.company?.code,
          agentId: agentOnBoarding?.agent?.code,
        })
      );

      let body = {
        externalReference: "CREATE_SALE_300",
        // policySaleReference: process.env.REACT_APP_ENV_TYPE === 'PROD' ? res?.data?.policySaleReference : res?.data?.data?.policySaleReference,
        policySaleReference: res?.data?.data?.policySaleReference,
        policyAttachments: [
          {
            externalReference: "IMAGES",
            // policyReference: process.env.REACT_APP_ENV_TYPE === 'PROD' ? res?.data?.policies?.[0]?.policyReference : res?.data?.data?.policies?.[0]?.policyReference,
            policyReference: res?.data?.data?.policies?.[0]?.policyReference,
            attachments: [
                // ...policyAttOpt.map((val, i) => ({
                //   instanceId: (101 + i).toString(),
                //   fileName: files[i].name.split(".")[0],
                //   fileExtension: files[i].name.split(".")[1],
                //   productAttachmentOptionInstanceId: val.instanceId,
                // })),
                {
                  instanceId: "101",
                  fileName: files?.document_of_proof?.name?.split(".")[0],
                  fileExtension: files?.document_of_proof?.name?.split(".")[1],
                  productAttachmentOptionInstanceId: policyAttOpt.find((opt) => opt.code === "IDD").instanceId,
                },
            ],
          },
        ],
      };
      // let file = [...files];
      let file= [files?.document_of_proof]
      let tags = Array(policyAttOpt.length)
        .fill()
        .map((_, i) => (101 + i).toString());
      await dispatch(policySaleAttachments({ body, file, tags }));
      
      setIsLoading(true);
      handleNext();
    } catch (err) {
      console.log(err);
    }
  };

  const beneficiariesState = useSelector(
    (state) => state?.products?.beneficiaries
  );

  useEffect(() => {
    setBeneficiaries(beneficiariesState);
  }, []);

  const [beneficiaries, setBeneficiaries] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      percentage: "",
      dob: new Date(),
      postalAddress: "",
      gender: "",
      country: "",
      telephoneNumber: "",
      idNumber: "",
    },
  ]);

  const handleChangeBene = (evt, index) => {
    var arr = [...beneficiaries];
    arr[index] = { ...arr[index], [evt.target.name]: evt.target.value };
    setBeneficiaries(arr);
    dispatch(
      updateCustomAttributes({
        type: "BENEFICIARIES",
        payload: arr,
      })
    );
  };

  const handleAdd = () => {
    setBeneficiaries([
      ...beneficiaries,
      {
        firstName: "",
        lastName: "",
        email: "",
        number: "",
        percentage: "",
        dob: new Date(),
        postalAddress: "",
        gender: "",
        country: "",
        telephoneNumber: "",
        idNumber: "",
      },
    ]);
  };

  const handleDelete = (i) => { 
    if (beneficiaries?.length <= 1) return;
    var arr = [...beneficiaries];

    arr.splice(i, 1); // 2nd parameter means remove one item only

    setBeneficiaries(arr);
    dispatch(
      updateCustomAttributes({
        type: "BENEFICIARIES",
        payload: arr,
      })
    );
  };

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  const [isTyped, setIsTyped] = useState(false);

  useEffect(() => {
    dispatch(updateAccordion(expand));
  }, [expand]);


  useEffect(() => {
    var divs = document.querySelectorAll("#accord-number");
    var divsArray = [].slice.call(divs);
    var displayNone = divsArray.filter(function (el) {
      return getComputedStyle(el).display === "none";
    });
    var displayShow = divsArray.filter(function (el) {
      return getComputedStyle(el).display !== "none";
    });
    var numberOfVisibleDivs = displayShow.length;
    getRows(+numberOfVisibleDivs).map((d, i) => (divs[i].innerHTML = i + 1));
  }, []);

  const customAttribues = useSelector(
    (state) => state?.products?.customAttribues
  );

  const options = useSelector(
    (state) =>
      state?.products?.riskAttributes
        ?.find((x) => x.code === RISKS.UQQ)
        ?.attributes?.find((y) => y.name === ATTRIBUTES.LIVES_COVERED)?.value
  );


  // useEffect(() => {
  //   var arr = [];
  //   const childRisk = riskAttributes?.find((x) => x.code === "CHI")?.attributes;
  //   getRows(customAttributes?.numberOfChildren)?.map((item, index) => {
  //     var relationship = childRisk?.find(
  //       (value) => value?.name === ATTRIBUTES.CHILD_RELATIONSHIP
  //     )?.name;
  //     var gender = childRisk?.find(
  //       (value) => value?.name === ATTRIBUTES.CHILD_GENDER
  //     )?.name;
  //     var relationshipValue =
  //       childState?.[index]?.[
  //       childRisk?.find(
  //         (value) => value?.name === ATTRIBUTES.CHILD_RELATIONSHIP
  //       )?.name
  //       ];
  //     var genderValue =
  //       childState?.[index]?.[
  //       childRisk?.find((value) => value?.name === ATTRIBUTES.CHILD_GENDER)
  //         ?.name
  //       ];
  //     arr.push({
  //       [relationship]: relationshipValue ?? JSON.parse(
  //         childRisk?.find(
  //           (value) => value.name === ATTRIBUTES.CHILD_RELATIONSHIP
  //         )?.settings
  //       )?.items?.[0],
  //       [gender]: genderValue ?? JSON.parse(
  //         childRisk?.find((value) => value.name === ATTRIBUTES.CHILD_GENDER)
  //           ?.settings
  //       )?.items?.[0],
  //     });
  //     if (getRows(customAttributes?.numberOfChildren)?.length - 1 === index) {
  //       dispatch(
  //         updateCustomAttributes({ type: "CHILD_ATTRIBUTES", payload: arr })
  //       );
  //     }
  //   });

  //   var arr1 = [];
  //   getRows(totalParents())?.map((item, index) => {
  //     arr1.push({
  //       'Parent Gender': parentState?.[index]?.['Parent Gender'] ?? 'Female',
  //       'Parent Relationship to Main Member': parentState?.[index]?.['Parent Relationship to Main Member'] ?? 'Father'
  //     })
  //     if (getRows(totalParents())?.length - 1 === index) {
  //       dispatch(
  //         updateCustomAttributes({ type: "PARENT_ATTRIBUTES", payload: arr1 })
  //       );
  //     }
  //   })

  //   var arr2 = [];
  //   getRows(getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)?.value)?.map((item, index) => {
  //     arr2.push({
  //       'Extended Fam Relationship to Main Member': parentState?.[index]?.['Extended Fam Relationship to Main Member'] ?? 'Grand-father',
  //       'Extended Family Gender': parentState?.[index]?.['Extended Family Gender'] ?? 'Female'
  //     })
  //     if (getRows(getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)?.value)?.length - 1 === index) {
  //       dispatch(
  //         updateCustomAttributes({ type: "EXTENDED_FAMILY_ATTRIBUTES", payload: arr2 })
  //       );
  //     }
  //   })

  //   const spouseAttributes = riskAttributes?.find((x) => x.code === 'SPO')?.attributes;
  //   var arr3 = [...spouseAttributes];
  //   const indexOfRelation = spouseAttributes?.findIndex((x) => x.name === 'Spouse Relationship to Main Member')
  //   const indexOfGender = spouseAttributes?.findIndex((x) => x.name === 'Spouse Gender')
  //   arr3[indexOfRelation] = { ...arr3[indexOfRelation], value: arr3[indexOfRelation]?.value ?? 'Husband' };
  //   arr3[indexOfGender] = { ...arr3[indexOfGender], value: arr3[indexOfGender]?.value ?? 'Female' }
  //   dispatch(updatedAttributes(arr3, 'SPO'))
  // }, []);

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Sisekelo Funeral Plan</h3>
            <h2 className="primary-color f-800">Select your cover</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Sisekelo Funeral Plan</p>
          <h2 className="primary-color f-800">Select your cover</h2>
        </div>
      )}
      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        <Info>
          <p className="primary-color">
            Please complete the details below for the policy holder and
            beneficiaries, click “Next” by scrolling to the bottom of the page
            to proceed to payment
          </p>
        </Info>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <h5 className="primary-color f-700 text-left">
            Please enter your personal details here:
          </h5>
        </div>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <Accordion
            defaultExpanded={expand.policy}
            onChange={() => setExpand({ ...expand, policy: !expand.policy })}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">
                    1
                  </span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add policy holder details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                <TextInput
                  id={"firstName"}
                  label={"First name"}
                  name={"firstName"}
                  value={inputs?.firstName}
                  maxLength={30}
                  errors={errors?.["firstName"]}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <TextInput
                  id={"lastName"}
                  label={"Last name or surname"}
                  name={"lastName"}
                  value={inputs?.lastName}
                  maxLength={30}
                  errors={errors?.["lastName"]}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />

                {/* //to be updated to state and passed in createPolicySale */}
                {/* //new field for Gender added */}

                <div className="form-group mt-3">
                  <label className="primary-color f-700">Gender*</label>

                  <ReactSelect
                    id={`mainGender`}
                    placeholder="Select gender"
                    value={inputs?.mainGender}
                    options={[
                      { value: "Male", label: "Male" },
                      { value: "Female", label: "Female" },
                    ]}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "mainGender", value: e },
                      });
                    }}
                  />
                  {errors?.["mainGender"] && (
                    <span
                      className="text-danger errors"
                      // style={{ marginLeft: 10 }}
                    >
                      {errors?.["mainGender"] && errors?.["mainGender"][0]}
                    </span>
                  )}
                </div>

                <NumberInput
                  id={"nationalityId"}
                  label={"Identification / Passport number"}
                  name={"nationalityId"}
                  value={inputs?.nationalityId}
                  errors={errors?.["nationalityId"]}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <FileUpload
                  title={policyAttachments?.description}
                  id="document_of_proof"
                  name="document_of_proof"
                  onChange={handleFileUpload}
                  required={true}
                  // value={files[0] && files[0].length && files[0].name}
                  value={files && files.document_of_proof}
                  errors={errors?.["document_of_proof"]}
                  accept=".jpg,.png,.pdf,.jpeg,.bmp"
                />
                <div
                  style={{
                    color: "#3C4096",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Acceptable file formats: JPG, JPEG, BMP, PNG or PDF
                </div>

                <DateInput
                  id={"dob"}
                  label={"Date of birth"}
                  name={"dob"}
                  minDate={calculateMinDate("parent")}
                  errors={errors?.["dob"]}
                  maxDate={calculateMaxDate("parent")}
                  value={inputs?.dob}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                  // placeholder="dd/mm/yyyy"
                />
                <TextInput
                  id={"nationality"}
                  label={"Nationality"}
                  name={"nationality"}
                  errors={errors?.["nationality"]}
                  value={inputs?.nationality}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <NumberInput
                  id={"incomeTax"}
                  label={"Income tax number"}
                  name={"incomeTax"}
                  errors={errors?.["incomeTax"]}
                  value={inputs?.incomeTax}
                  onChange={(e) => handleChangePolicy(e)}
                  placeholder="If applicable"
                  required={false}
                />
                <div className="form-group mt-3">
                  <label className="primary-color f-700">Marital status*</label>
                  <ReactSelect
                    id={`maritalStatus`}
                    placeholder="Select status"
                    value={inputs?.maritalStatus}
                    options={[
                      { value: "Married", label: "Married" },
                      { value: "Single", label: "Single" },
                    ]}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "maritalStatus", value: e },
                      });
                    }}
                  />
                  {errors?.["maritalStatus"] && (
                    <span
                      className="text-danger errors"
                      // style={{ marginLeft: 10 }}
                    >
                      {errors?.["maritalStatus"] &&
                        errors?.["maritalStatus"][0]}
                    </span>
                  )}
                </div>
                <div className="row">
                  {width > 1024 ? (
                    <>
                      <div className="col-4">
                        <CountryInput
                          id={"mobilePhoneCode"}
                          label={"Country code"}
                          name={"mobilePhoneCode"}
                          errors={errors?.["mobilePhoneCode"]}
                          value={inputs?.mobilePhoneCode ?? "+268"}
                          placeholder={"+255"}
                          onChange={(e) =>
                            handleChangePolicy(e, "mobilePhoneCode")
                          }
                          required={true}
                          country={true}
                        />
                      </div>
                      <div className="col-8">
                        <NumberInput
                          id={"mobilePhone"}
                          label={"Mobile number"}
                          name={"mobilePhone"}
                          errors={errors?.["mobilePhone"]}
                          value={
                            Number(inputs?.mobilePhone) === 0 ||
                            isNaN(Number(inputs?.mobilePhone)) ||
                            inputs?.mobilePhone === undefined ||
                            inputs?.mobilePhone === null
                              ? ""
                              : Number(inputs?.mobilePhone).toString()
                          }
                          onChange={(e) => handleChangePolicy(e)}
                          required={true}
                          mobile={true}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12">
                        <CountryInput
                          id={"mobilePhoneCode"}
                          label={"Country code"}
                          name={"mobilePhoneCode"}
                          errors={errors?.["mobilePhoneCode"]}
                          value={inputs?.mobilePhoneCode ?? "+268"}
                          placeholder={"+255"}
                          onChange={(e) =>
                            handleChangePolicy(e, "mobilePhoneCode")
                          }
                          required={true}
                          country={true}
                        />
                      </div>
                      <div className="col-12">
                        <NumberInput
                          id={"mobilePhone"}
                          label={"Mobile number"}
                          name={"mobilePhone"}
                          errors={errors?.["mobilePhone"]}
                          value={
                            Number(inputs?.mobilePhone) === 0 ||
                            isNaN(Number(inputs?.mobilePhone)) ||
                            inputs?.mobilePhone === undefined ||
                            inputs?.mobilePhone === null
                              ? ""
                              : Number(inputs?.mobilePhone).toString()
                          }
                          onChange={(e) => handleChangePolicy(e)}
                          required={true}
                          mobile={true}
                        />
                      </div>
                    </>
                  )}
                </div>
                <NumberInput
                  id={"telePhone"}
                  label={"Telephone number"}
                  name={"telePhone"}
                  errors={errors?.["telePhone"]}
                  value={inputs?.telePhone}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <TextInput
                  id={"eMail"}
                  label={"Email address"}
                  name={"eMail"}
                  errors={errors?.["eMail"]}
                  value={inputs?.eMail}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <TextInput
                  textarea={true}
                  id={"physicalAddress"}
                  label={"Physical address"}
                  errors={errors?.["physicalAddress"]}
                  name={"physicalAddress"}
                  placeholder="Address"
                  value={inputs?.physicalAddress}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <TextInput
                  id={"postalAddress"}
                  textarea={true}
                  label={"Postal address"}
                  errors={errors?.["postalAddress"]}
                  name={"postalAddress"}
                  placeholder="If not the same as physical address"
                  value={inputs?.postalAddress}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <div style={{ height: "10px" }} />
                <TwoButtons
                  id={getManAttributes(ATTRIBUTES.LIBERTY_POLICIES)?.name}
                  isField={true}
                  half={true}
                  title={`${
                    getManAttributes(ATTRIBUTES.LIBERTY_POLICIES)?.description
                  }`}
                  state={
                    inputs[getManAttributes(ATTRIBUTES.LIBERTY_POLICIES)?.name]
                  }
                  onClick={(val) =>
                    handleChangePolicy({
                      target: {
                        name: getManAttributes(ATTRIBUTES.LIBERTY_POLICIES)
                          ?.name,
                        value: val,
                      },
                    })
                  }
                  options={[
                    {
                      name: "Yes",
                      value: "Yes",
                    },
                    {
                      name: "No",
                      value: "No",
                    },
                  ]}
                />
                {riskAttributes
                  .find((x) => x.code === "MAN")
                  ?.attributes?.filter(
                    (y) => y.name !== "Main Member Other Liberty Policies"
                  )
                  ?.map((y) => renderComponents(y))}
              </div>
            </AccordionDetails>
          </Accordion>
          {errors && errors["inputState"] && (
            <span className="errors">{errors["inputState"][0]}</span>
          )}
          {option?.value === 1 && (
            <>
              {customAttributes?.spouse_cover !== 1 ? (
                <>
                  {" "}
                  <div style={{ height: "20px" }} />
                  <Accordion
                    disabled={
                      options?.value === 1 ||
                      customAttributes?.spouse_cover === 1
                    }
                    defaultExpanded={
                      spouseCoverAttribute?.value === 1 ? false : expand.spouse
                    }
                    onChange={() =>
                      setExpand({ ...expand, spouse: !expand.spouse })
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="d-flex flex-row">
                        <div
                          style={{
                            borderRadius: 99,
                            marginRight: 10,
                            backgroundColor: "white",
                            width: 30,
                            height: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="primary-color f-700"
                            id="accord-number"
                          >
                            2
                          </span>
                        </div>
                        <p
                          className="f-700 p-0 m-0"
                          style={{ color: "#3C4096" }}
                        >
                          Add spouse details here
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="p-0 mt-4">
                      <div
                        style={{
                          border: "1px solid #00000030",
                          padding: 20,
                          borderRadius: 5,
                        }}
                      >
                        {riskAttributes
                          .find((x) => x.code === "SPO")
                          ?.attributes.map((y) => renderComponents(y))}
                      </div>
                    </AccordionDetails>
                  </Accordion>{" "}
                </>
              ) : (
                <></>
              )}
              {errors && errors["spouseState"] && (
                <span className="errors">{errors["spouseState"][0]}</span>
              )}
              {customAttributes?.child_cover !== 1 ? (
                <>
                  <div style={{ height: "20px" }} />
                  <Accordion
                    disabled={
                      options?.value === 1 ||
                      customAttributes?.child_cover === 1
                    }
                    defaultExpanded={
                      childCoverAttribute?.value === 1 ? false : expand.child
                    }
                    onChange={() =>
                      setExpand({ ...expand, child: !expand.child })
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="d-flex flex-row">
                        <div
                          style={{
                            borderRadius: 99,
                            marginRight: 10,
                            backgroundColor: "white",
                            width: 30,
                            height: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="primary-color f-700"
                            id="accord-number"
                          >
                            3
                          </span>
                        </div>
                        <p
                          className="f-700 p-0 m-0"
                          style={{ color: "#3C4096" }}
                        >
                          Add child details here
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="p-0 mt-4">
                      {getRows(customAttribues?.numberOfChildren).map(
                        (x, i) => (
                          <div
                            style={{
                              border: "1px solid #00000030",
                              marginTop: 20,
                              padding: 20,
                              borderRadius: 5,
                            }}
                          >
                            <p className="primary-color f-700">
                              Child #{i + 1}
                            </p>
                            {riskAttributes
                              .find((x) => x.code === "CHI")
                              ?.attributes.map((y) =>
                                renderComponents(y, "child", i)
                              )}
                          </div>
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : (
                <></>
              )}
              {errors && errors["childState"] && (
                <span className="errors">{errors["childState"][0]}</span>
              )}
              {getCoverAmountInt(
                getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
              ) !== 30000 && getCoverAmountInt(getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value) !== 50000 &&
              (
                <>
                  {getAttributes(ATTRIBUTES.PARENTS)?.value !== 1 ? (
                    <>
                      {" "}
                      <div style={{ height: "20px" }} />
                      <Accordion
                        disabled={
                          options?.value === 1 ||
                          getAttributes(ATTRIBUTES.PARENTS)?.value === 1
                        }
                        defaultExpanded={
                          parentCoverAttribute?.value === 1
                            ? false
                            : expand.parent
                        }
                        onChange={() =>
                          setExpand({ ...expand, parent: !expand.parent })
                        }
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <div className="d-flex flex-row">
                            <div
                              style={{
                                borderRadius: 99,
                                marginRight: 10,
                                backgroundColor: "white",
                                width: 30,
                                height: 30,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span
                                className="primary-color f-700"
                                id="accord-number"
                              >
                                4
                              </span>
                            </div>
                            <p
                              className="f-700 p-0 m-0"
                              style={{ color: "#3C4096" }}
                            >
                              Add parent details here
                            </p>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="p-0 mt-4">
                          {getRows(totalParents()).map((x, i) => (
                            <div
                              style={{
                                border: "1px solid #00000030",
                                marginTop: 20,
                                padding: 20,
                                borderRadius: 5,
                              }}
                            >
                              <p className="primary-color f-700">
                                Parent #{i + 1}
                              </p>
                              {riskAttributes
                                .find((x) => x.code === "PAR")
                                ?.attributes.map((y) =>
                                  renderComponents(y, "parent", i)
                                )}
                            </div>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </>
                  ) : (
                    <></>
                  )}
                  {errors && errors["parentState"] && (
                    <span className="errors">{errors["parentState"][0]}</span>
                  )}
                </>
              )}
              {getCoverAmountInt(
                getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
              ) !== 30000 &&
                getCoverAmountInt(
                  getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
                ) !== 20000 &&  getCoverAmountInt(getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value) !== 50000 &&
                (
                  <>
                    {getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value !== 1 ? (
                      <>
                        {" "}
                        <div style={{ height: "20px" }} />
                        <Accordion
                          disabled={
                            options?.value === 1 ||
                            getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value ===
                              1
                          }
                          defaultExpanded={
                            parentCoverAttribute?.value === 1
                              ? false
                              : expand.parent
                          }
                          onChange={() =>
                            setExpand({ ...expand, parent: !expand.parent })
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                          >
                            <div className="d-flex flex-row">
                              <div
                                style={{
                                  borderRadius: 99,
                                  marginRight: 10,
                                  backgroundColor: "white",
                                  width: 30,
                                  height: 30,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className="primary-color f-700"
                                  id="accord-number"
                                >
                                  5
                                </span>
                              </div>
                              <p
                                className="f-700 p-0 m-0"
                                style={{ color: "#3C4096" }}
                              >
                                Add extended family details here
                              </p>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className="p-0 mt-4">
                            {getRows(
                              getAttributes(
                                ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY
                              )?.value
                            ).map((x, i) => (
                              <div
                                style={{
                                  border: "1px solid #00000030",
                                  marginTop: 20,
                                  padding: 20,
                                  borderRadius: 5,
                                }}
                              >
                                <p className="primary-color f-700">
                                  Extended Family #{i + 1}
                                </p>
                                {riskAttributes
                                  .find((x) => x.code === "EXF")
                                  ?.attributes.map((y) =>
                                    renderComponents(y, "extended", i)
                                  )}
                              </div>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </>
                    ) : (
                      <></>
                    )}
                    {errors && errors["extendedState"] && (
                      <span className="errors">
                        {errors["extendedState"][0]}
                      </span>
                    )}
                  </>
                )}
            </>
          )}
          <div style={{ height: "20px" }} />
          <Accordion
            defaultExpanded={expand.beneficiaries}
            onChange={() =>
              setExpand({ ...expand, beneficiaries: !expand.beneficiaries })
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="pane41a-content"
              id="panel4a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">
                    {option?.value === 0 ? "2" : "6"}
                  </span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add beneficiaries details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              {getRows(beneCount)?.map((x, i) => (
                <div
                  style={{
                    border: "1px solid #00000030",
                    marginTop: 20,
                    padding: 20,
                    borderRadius: 5,
                  }}
                >
                  <p className="primary-color f-700">Beneficiary #{i + 1}</p>
                  {riskAttributes
                    .find((x) => x.code === "BEN")
                    ?.attributes?.filter((x) => x.isSelected !== false)
                    ?.map((y) => renderComponents(y, "bene", i))}

                  <div style={{ marginTop: 20 }} />
                  <TwoButtons
                    id={`Gender ${i + 1}`}
                    title={"Gender"}
                    isField={true}
                    half={true}
                    errors={errors?.[`Gender ${i + 1}`]}
                    state={beneficiaries[i]?.["gender"]}
                    onClick={(e) =>
                      handleChangeBene(
                        { target: { name: "gender", value: e } },
                        i
                      )
                    }
                    options={[
                      {
                        name: "Male",
                        value: "Male",
                      },
                      {
                        name: "Female",
                        value: "Female",
                      },
                    ]}
                    required={true}
                  />
                  <div className="form-group mt-3">
                    <label className="primary-color f-700">
                      Relationship with policy holder
                    </label>
                    <ReactSelect
                      styles={customStyles}
                      options={[
                        {
                          label: "Self",
                          value: "Self",
                        },
                        {
                          label: "Other",
                          value: "Other",
                        },
                        {
                          label: "Spouse",
                          value: "Spouse",
                        },
                        {
                          label: "Son",
                          value: "Son",
                        },
                        {
                          label: "Daughter",
                          value: "Daughter",
                        },
                        {
                          label: "Father",
                          value: "Father",
                        },
                        {
                          label: "Mother",
                          value: "Mother",
                        },
                        {
                          label: "Brother",
                          value: "Brother",
                        },
                        {
                          label: "Sister",
                          value: "Sister",
                        },
                        {
                          label: "Father-in-law",
                          value: "Father-in-law",
                        },
                        {
                          label: "Mother-in-law",
                          value: "Mother-in-law",
                        },
                        {
                          label: "Uncle",
                          value: "Uncle",
                        },
                        {
                          label: "Aunt",
                          value: "Aunt",
                        },
                        {
                          label: "Grandfather",
                          value: "Grandfather",
                        },
                        {
                          label: "Grandmother",
                          value: "Grandmother",
                        },
                        {
                          label: "Brother-in-law",
                          value: "Brother-in-law",
                        },
                        {
                          label: "Sister-in-law",
                          value: "Sister-in-law",
                        },
                        {
                          label: "Nephew",
                          value: "Nephew",
                        },
                        {
                          label: "Niece",
                          value: "Niece",
                        },
                      ]}
                      value={
                        beneficiaries[i]?.["relationship"]
                          ? {
                              label: beneficiaries[i]?.["relationship"],
                              value: beneficiaries[i]?.["relationship"],
                            }
                          : {}
                      }
                      onChange={(e) =>
                        handleChangeBene(
                          { target: { name: "relationship", value: e.value } },
                          i
                        )
                      }
                    />
                  </div>
                  <NumberInput
                    id={`Phone Number ${i + 1}`}
                    label={"Mobile number"}
                    name={"number"}
                    placeholder={"+268123456789"}
                    value={beneficiaries[i]?.["number"]}
                    errors={errors[`Phone Number ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                  />
                  <NumberInput
                    id={`Telephone Number ${i + 1}`}
                    label={"Telephone number"}
                    name={"telephoneNumber"}
                    placeholder={"+268123456789"}
                    value={beneficiaries[i]?.["telephoneNumber"]}
                    errors={errors[`Telephone number ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={false}
                  />

                  <TextInput
                    id={`Email ${i + 1}`}
                    label={"Email address"}
                    name={"email"}
                    value={beneficiaries[i]?.["email"]}
                    errors={errors[`Email ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    required={true}
                  />
                  <TextInput
                    id={`Postal Address ${i + 1}`}
                    label={"Postal address"}
                    name={"postalAddress"}
                    value={beneficiaries[i]?.["postalAddress"]}
                    errors={errors[`Postal Address ${i + 1}`]}
                    onChange={(e) => handleChangeBene(e, i)}
                    placeholder={"Postal address"}
                    required={false}
                  />
                  <div className="form-group mt-3">
                    <label className="primary-color f-700">
                      Percentage of benefit*
                    </label>
                    <ReactSelect
                      id={`Percentage ${i + 1}`}
                      placeholder="0"
                      defaultValue={[
                        ...percentageArr.map((x) => ({
                          label: `${x.name}`,
                          value: x.value,
                        })),
                        ...range(0, 100).map((x, i) => ({
                          label: `${x}%`,
                          value: x,
                        })),
                      ].find(
                        (z) => z.value === beneficiaries[i]?.["percentage"]
                      )}
                      value={[
                        ...percentageArr.map((x) => ({
                          label: `${x.name}`,
                          value: x.value,
                        })),
                        ...range(0, 100).map((x, i) => ({
                          label: `${x}%`,
                          value: x,
                        })),
                      ].find(
                        (z) => z.value === beneficiaries[i]?.["percentage"]
                      )}
                      options={
                        isTyped
                          ? range(0, 100).map((x, i) => ({
                              label: `${x}%`,
                              value: x,
                            }))
                          : percentageArr.map((x) => ({
                              label: `${x.name}`,
                              value: x.value,
                            }))
                      }
                      onInputChange={(e) => {
                        if (e > 0) {
                          setIsTyped(true);
                        } else {
                          setIsTyped(false);
                        }
                      }}
                      onChange={(e) => {
                        handleChangeBene(
                          { target: { name: "percentage", value: e.value } },
                          i
                        );
                      }}
                      isSearchable={true}
                    />
                  </div>
                  {(
                    errors[`Percentage ${i + 1}`] ||
                    errors[`beneficiariesSplit`]
                  )?.length && (
                    <span
                      className="text-danger errors"
                      style={{ marginLeft: 0 }}
                    >
                      {errors[`Percentage ${i + 1}`] ||
                        (errors[`beneficiariesSplit`] &&
                          errors[`Percentage ${i + 1}`]) ||
                        errors[`beneficiariesSplit`][0]}
                    </span>
                  )}
                  <div className="d-flex justify-content-end w-100 mt-4">
                    {beneCount > 1 && (
                      <button
                        className="delete-button"
                        onClick={() => {
                          if (beneCount > 1) {
                            var arr = [...beneficiaries];
                            arr.splice(i, 1);
                            setBeneficiaries(arr);
                            dispatch(
                              updateCustomAttributes({
                                type: "BENEFICIARIES",
                                payload: arr,
                              })
                            );
                            setBeneCount(beneCount - 1);
                          }
                        }}
                      >
                        Delete
                      </button>
                    )}
                    {beneCount < 10 && getRows(beneCount)?.length - 1 === i && (
                      <button
                        className="add-button"
                        onClick={() => {
                          var arr = [...beneficiaries];
                          setBeneficiaries(arr);
                          dispatch(
                            updateCustomAttributes({
                              type: "BENEFICIARIES",
                              payload: arr,
                            })
                          );
                          setBeneCount(beneCount + 1);
                        }}
                      >
                        Add Beneficiaries
                      </button>
                    )}
                    {/* <button className="delete-button" onClick={() => setBeneCount(beneCount - 1)}>
                  Delete
                </button>
                <button
                  className="add-button"
                  onClick={() => setBeneCount(beneCount + 1)}
                >
                  Add Beneficiaries
                </button> */}
                  </div>
                </div>
              ))}
              {/* <div className="d-flex justify-content-end w-100 mt-4">
                <button
                  className="delete-button"
                  onClick={() => setBeneCount(beneCount - 1)}
                >
                  Delete
                </button>
                <button
                  className="add-button"
                  onClick={() => setBeneCount(beneCount + 1)}
                >
                  Add Beneficiaries
                </button>
              </div> */}
            </AccordionDetails>
          </Accordion>
          {errors && errors["beneficiaries"] && (
            <span className="errors">{errors["beneficiaries"][0]}</span>
          )}
        </div>
      </div>

      <div style={{ height: "50px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium </h5>
          <h5 className="text-white f-700">
            SZL{" "}
            {calculatorResponse?.risks[0]?.rating?.total
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0}
          </h5>
        </div>
      </div>

      <NavigationButtons
        back={handleBack}
        isBackDisabled={isLoading}
        loading={isLoading}
        next={() => {
          if (isLoading) {
            return;
          }
          handleSubmit();
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

const schema = {
  firstName: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-'\sa-zA-Z]+$/,
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-'\sa-zA-Z]+$/,
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  nationalityId: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[A-Za-z0-9]+$/,
      message: "can only contain alphabets and numbers",
    },
  },
  dob: {
    presence: {
      allowEmpty: false,
    },
  },
  nationality: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[A-Za-z]+$/,
      message: "can only contain alphabets",
    },
  },
  maritalStatus: {
    presence: {
      allowEmpty: false,
    },
  },
  mobilePhone: {
    presence: {
      allowEmpty: false,
      message: "^Mobile number can't be blank",
    },
    format: {
      pattern:/^(\+\d{1,3}[- ]?)?\d{8,10}$/,
      message: '^ Invalid mobile number'
    }
  },
  mobilePhoneCode: {
    presence: {
      allowEmpty: false,
    },
  },
  eMail: {
    presence: {
      allowEmpty: false,
      message: "^ Email can't be blank"
    },
    email: true,
  },
  physicalAddress: {
    presence: {
      allowEmpty: false,
    },
  },
  mainGender: {
    presence: {
      allowEmpty: false,
    },
  },
};

const percentageArr = [
  {
    name: "10%",
    value: 10,
  },
  {
    name: "20%",
    value: 20, 
  },
  {
    name: "30%",
    value: 30,
  },
  {
    name: "40%",
    value: 40,
  },
  {
    name: "50%",
    value: 50,
  },
  {
    name: "60%",
    value: 60,
  },
  {
    name: "70%",
    value: 70,
  },
  {
    name: "80%",
    value: 80,
  },
  {
    name: "90%",
    value: 90,
  },
  {
    name: "100%",
    value: 100,
  },
];

export default Step2;


const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "#00000080",
  }),
};