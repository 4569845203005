import React, { useEffect, useState } from "react";
import { Dropdown, NumberInput, TwoButtons } from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import style from "../Form.module.css";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimension";
import classNames from "classnames";
import {
  updateCustomAttributes,
  updatedAttributes,
  executeCalculator,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";

const Step2 = ({ handleNext, handleBack }) => {
  const [inputs, setInputs] = useState({
    coverOptions: 0,
    options: 0,
    children: 0,
    additionalChildren: 0,
    parent: 0,
    parentAbove65: 0,
    parentAbove80: 0,
  });

  const UQQRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "UQQ")?.attributes
  );

  const getAttributes = (value) => {
    return UQQRiskAttributes.find((x) => x.name === value);
  };

  const getCoverAmountInt = (amount) => {
    var string = amount;
    string = string?.replace("SZL", "");
    string = string?.replace(",", "");
    return parseInt(string);
  };

  const childState = useSelector((state) => state.products?.childAttributes);

  const parentState = useSelector((state) => state.products?.parentAttributes);

  const [childAttributes, setChildAttributes] = useState(childState);

  const [parentAttributes, setParentAttributes] = useState(parentState);

  const optionValue = getAttributes(ATTRIBUTES.LIVES_COVERED)?.value;
  const mainCover = getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value?.replace(
    "SZL",
    ""
  );
  console.log("mainCover-->", mainCover);
  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "UQQ")?.attributes
  );

  const customAttributes = useSelector(
    (state) => state?.products.customAttribues
  );

  const dispatch = useDispatch();

  const handleChange = (evt, instanceId) => {
    var arr = [...riskAttributes];
    var index = riskAttributes.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, "UQQ"));
  };

  const [errors, setErrors] = useState({});
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);

  const getConstraints = () => {
    var val = {};
    if (optionValue === 0) {
      val = {
        ...val,
      };
      return val;
    } else {
      val = {
        ...val,
        "Spouse Cover": {
          presence: {
            allowEmpty: false,
          },
        },
        "Child Cover": {
          presence: {
            allowEmpty: false,
          },
        },
      };
      if (customAttributes?.child_cover === 0) {
        val = {
          ...val,
          "Number of Childrens": {
            presence: {
              allowEmpty: false,
              message: "^Number of children can't be blank",
            },
          },
        };
      }

      if (
        getCoverAmountInt(getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value) !==
          30000 &&
        getCoverAmountInt(getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value) !==
          50000
      ) {
        val = {
          ...val,
          [getAttributes(ATTRIBUTES.PARENTAL_COVER)?.name]: {
            presence: {
              allowEmpty: false,
            },
          },
          [getAttributes(ATTRIBUTES.PARENT_IN_LAW)?.name]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
        if (getAttributes(ATTRIBUTES.PARENTAL_COVER)?.value === 0) {
          val = {
            ...val,
            [getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS)?.name]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        }
        if (getAttributes(ATTRIBUTES.PARENT_IN_LAW)?.value === 0) {
          val = {
            ...val,
            [getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW)?.name]: {
              presence: {
                allowEmpty: false,
                message: "^Number of parents-in-law can't be blank",
              },
            },
          };
        }
      }

      if (
        getCoverAmountInt(getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value) !==
          30000 &&
        getCoverAmountInt(getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value) !==
          20000 &&
        getCoverAmountInt(getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value) !==
          50000
      ) {
        val = {
          ...val,
          [getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.name]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
        if (getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === 0) {
          val = {
            ...val,
            [getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)?.name]: {
              presence: {
                allowEmpty: false,
              },
            },
          };
        }
      }
      return val;
    }
  };

  const types = useSelector((state) => state?.products?.types?.types);

  const state = useSelector((state) => state.products.riskAttributes);

  var coverOptions = getAttributes(ATTRIBUTES.COVER_OPTIONS)?.value;

  const handleSubmit = async () => {
    try {
      const errors = validate(
        {
          "Spouse Cover": customAttributes?.spouse_cover,
          "Child Cover": customAttributes?.child_cover,
          "Number of Childrens": customAttributes?.numberOfChildren,
          [getAttributes(ATTRIBUTES.PARENTAL_COVER)?.name]: getAttributes(
            ATTRIBUTES.PARENTAL_COVER
          )?.value,
          [getAttributes(ATTRIBUTES.PARENT_IN_LAW)?.name]: getAttributes(
            ATTRIBUTES.PARENT_IN_LAW
          )?.value,
          [getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.name]: getAttributes(
            ATTRIBUTES.EXTENDED_FAMILY
          )?.value,
          [getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS)?.name]: getAttributes(
            ATTRIBUTES.NUMBER_OF_PARENTS
          )?.value,
          [getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW)?.name]:
            getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW)?.value,
          [getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)?.name]:
            getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)?.value,
        },
        getConstraints()
      );
      if (errors || errors !== undefined) {
        console.log("errorssss-->", errors);
        setErrors(errors);
        return errors;
      }
      console.log("errors-->", errors);
      setIsLoading(true);
      const res = await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference: types?.find((x) =>
              coverOptions === "Basic"
                ? x.name === "Basic Cover"
                : x.name === "Comprehensive Cover"
            )?.instanceId,
            risks: [
              {
                attributes: [
                  ...state
                    .find((x) => x.code === RISKS.UQQ)
                    ?.attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value:
                        y.name === ATTRIBUTES.LIVES_COVERED
                          ? y.value === 0
                            ? "Individual"
                            : "Family"
                          : y.name === ATTRIBUTES.COVER_OPTIONS
                          ? y.value === "Basic"
                            ? "Basic Cover"
                            : "Comprehensive Cover"
                          : y.name === "Children"
                          ? customAttributes?.child_cover === 0
                            ? "Yes"
                            : "No"
                          : y.name === "Number of Children"
                          ? customAttributes?.numberOfChildren ?? 0
                          : y.name === "Parents"
                          ? y.value === 0
                            ? "Yes"
                            : "No"
                          : y.name === "Parents-in-Law"
                          ? y.value === 0
                            ? "Yes"
                            : "No"
                          : y.name === "Extended Family"
                          ? y.value === 0
                            ? "Yes"
                            : "No"
                          : y.name === "Immediate Family"
                          ? customAttributes?.child_cover === 0
                            ? "Yes"
                            : "No"
                          : y.name === "Number of Immediate Family Members"
                          ? customAttributes?.numberOfChildren ?? 0
                          : y.value,
                    })),
                ],
                riskReference: state.find((x) => x.code === RISKS.UQQ)
                  ?.instanceId,
                code: state.find((x) => x.code === RISKS.UQQ)?.code,
              },
            ],
          },
        })
      );
      setIsLoading(false);
      handleNext();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  const parseString = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => {
      const val = a.split(":");
      obj[val[0]] = val[1];
    });
    return obj;
  };

  const productOptions = useSelector(
    (state) => state?.products?.executeCalculator?.productOptions?.[0]
  );

  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator?.productOptions?.[0]
  );

  const premiumAmountCalc = () => {
    var total = 0;
    var option = getAttributes(ATTRIBUTES.LIVES_COVERED)?.value;
    var coverAmount = productOptions?.risks?.[0]?.rating?.total;
    var parentRate = parseString(productOptions?.risks?.[0]?.rating?.message)[
      " Parents Rate "
    ];
    var familyRate = parseString(productOptions?.risks?.[0]?.rating?.message)[
      " Family Rate "
    ];
    var extendedRate =
      parseString(productOptions?.risks?.[0]?.rating?.message)[
        "  Extended Family Family Rate"
      ] ?? 0;
    const numberOfParent =
      getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS)?.value ?? 0;
    const numberOfParentInLaw =
      getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW)?.value ?? 0;
    const numberOfExtended =
      getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)?.value ?? 0;

    let overallTotal = 0;
    if (option === 0) {
      total = coverAmount;
      overallTotal = total;
    } else {
      total =
        (+numberOfParent + +numberOfParentInLaw) * parseInt(parentRate) +
        +numberOfExtended * parseInt(extendedRate);
      overallTotal = total + parseInt(familyRate);
    }

    dispatch(
      updateCustomAttributes({
        type: "PREMIUM",
        payload: overallTotal,
      })
    );
    if (overallTotal === 0) {
      return calculatorResponse?.risks[0]?.rating?.total
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return overallTotal;
  };

  useEffect(() => {
    dispatch(
      updateCustomAttributes({
        type: "PREMIUM",
        payload: premiumAmountCalc(),
      })
    );
  }, []);

  const handleChangeCustom = (val, name, code) => {
    let obj = { ...customAttributes, [name]: val };

    dispatch(
      updateCustomAttributes({
        type: "CUSTOM_ATTRIBUTES",
        payload: obj,
      })
    );
  };

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Sisekelo Funeral Plan</h3>
            <h2 className="primary-color f-800">Select your cover</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Sisekelo Funeral Plan</p>
          <h2 className="primary-color f-800">Select your cover</h2>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className={classNames(style.step_2_info, "mt-5 mb-4")}>
          <h4 className="primary-color f-700">{productOptions?.name ?? ""}</h4>
          <div className={style.step_2_info_container}>
            <h6 className="secondary-color f-700">Premium</h6>
            <div className="d-flex justify-content-between">
              <h6 className="primary-color f-700">Monthly cost</h6>
              <h6 className="primary-color f-700">
                SZL{" "}
                {premiumAmountCalc()
                  ? premiumAmountCalc()
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0
                  : 0}
              </h6>
            </div>
            <div style={{ height: "30px" }} />
            <h6 className="secondary-color f-700">Level of cover</h6>
            <div className="d-flex justify-content-between">
              <h6 className="primary-color f-700">Main member</h6>
              <h6 className="primary-color f-700">
                {getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value}
              </h6>
            </div>
            {riskAttributes?.[0]?.value === 0 ? null : (
              <>
                <div className="d-flex justify-content-between">
                  <h6 className="primary-color f-700">Spouse</h6>
                  <h6 className="primary-color f-700">
                    {getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value}
                  </h6>
                </div>
                <div className="d-flex justify-content-between">
                  <h6 className="primary-color f-700">Children (14-21)</h6>
                  <h6 className="primary-color f-700">
                    {/* {getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value} */}
                    SZL{" "}
                    {(parseInt(mainCover?.replace(",", "")) / 2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </h6>
                </div>
                <div className="d-flex justify-content-between">
                  <h6 className="primary-color f-700">Children (6-13)</h6>
                  <h6 className="primary-color f-700">
                    SZL{" "}
                    {(parseInt(mainCover?.replace(",", "")) / 4)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </h6>
                </div>
                <div className="d-flex justify-content-between">
                  <h6 className="primary-color f-700">Children (Under 5)</h6>
                  <h6 className="primary-color f-700">
                    SZL{" "}
                    {(parseInt(mainCover?.replace(",", "")) / 8)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </h6>
                </div>
                {/* {getCoverAmountInt(
                  getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
                ) !== 30000 &&
                  getCoverAmountInt(
                    getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
                  ) !== 50000 && (
                    <div className="d-flex justify-content-between">
                      <h6 className="primary-color f-700">
                        Parent/Parent inlaw
                      </h6>
                      <h6 className="primary-color f-700">
                        SZL 20,000
                      </h6>
                    </div>
                  )} */}
                {/* {getCoverAmountInt(
                  getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
                ) !== 20000 &&
                  getCoverAmountInt(
                    getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
                  ) !== 30000 &&
                  getCoverAmountInt(
                    getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
                  ) !== 50000 && (
                    <div className="d-flex justify-content-between">
                      <h6 className="primary-color f-700">Extended family</h6>
                      <h6 className="primary-color f-700">
                        SZL 10,000
                      </h6>
                    </div>
                  )} */}{" "}
              </>
            )}
          </div>
        </div>
        {getAttributes(ATTRIBUTES.LIVES_COVERED)?.value === 1 && (
          <>
            <TwoButtons
              id="spouse_cover"
              half={true}
              title={"Do you want to add your spouse?"}
              errors={errors?.["Spouse Cover"]}
              state={customAttributes?.spouse_cover}
              onClick={(value) => {
                handleChangeCustom(value, "spouse_cover", "SPOUSE_COVER");
              }}
              options={[
                {
                  name: "Yes",
                  value: 0,
                },
                {
                  name: "No",
                  value: 1,
                },
              ]}
            />
            <div style={{ height: "30px" }} />
            <TwoButtons
              id="child_cover"
              half={true}
              title={"Do you want to cover any children?"}
              errors={errors?.["Child Cover"]}
              state={customAttributes?.child_cover}
              onClick={(value) => {
                if (value === 1) {
                  var obj = {
                    ...customAttributes,
                    numberOfChildren: 0,
                    child_cover: value,
                  };
                  return dispatch(
                    updateCustomAttributes({
                      type: "CUSTOM_ATTRIBUTES",
                      payload: obj,
                    })
                  );
                }
                handleChangeCustom(value, "child_cover", "CHILD_COVER");
              }}
              options={[
                {
                  name: "Yes",
                  value: 0,
                },
                {
                  name: "No",
                  value: 1,
                },
              ]}
            />
            {customAttributes?.child_cover === 0 && (
              <div
                className={classNames(style.twoButtonContainer, "p-3")}
                id={"Number of Childrens"}
              >
                <p className="primary-color f-700">
                  {"How many children do you want to cover?"}
                </p>
                <NumberInput
                  half={true}
                  type="number"
                  customWidth={200}
                  hideLabel={true}
                  id={"Number of Childrens"}
                  value={customAttributes?.numberOfChildren}
                  errors={errors?.["Number of Childrens"]}
                  onChange={(e) => {
                    const { value } = e?.target;
                    var num = Number(value);
                    if (value === "" || (num >= 1 && num <= 6)) {
                      handleChangeCustom(
                        value === "" ? null : num,
                        "numberOfChildren",
                        "CHILD_COVER"
                      );
                    }
                  }}
                />
              </div>
            )}
            {getCoverAmountInt(
              getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
            ) !== 30000 &&
              getCoverAmountInt(
                getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
              ) !== 50000 && (
                <>
                  <div style={{ height: "30px" }} />
                  <TwoButtons
                    id="Parents"
                    half={true}
                    title={getAttributes(ATTRIBUTES.PARENTS)?.description}
                    errors={errors?.["Parents"]}
                    state={getAttributes(ATTRIBUTES.PARENTS)?.value}
                    onClick={(value) => {
                      if (value === 1) {
                        var arr = [...riskAttributes];
                        var index = riskAttributes.findIndex(
                          (x) =>
                            x.instanceId ===
                            getAttributes(ATTRIBUTES.PARENTS)?.instanceId
                        );
                        var index1 = riskAttributes.findIndex(
                          (x) =>
                            x.instanceId ===
                            getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS)
                              ?.instanceId
                        );
                        arr[index] = { ...arr[index], value: value };
                        arr[index1] = { ...arr[index1], value: 0 };
                        return dispatch(updatedAttributes(arr, "UQQ"));
                      }
                      setInputs({ ...inputs, parents: value });
                      handleChange(
                        value,
                        getAttributes(ATTRIBUTES.PARENTS)?.instanceId
                      );
                    }}
                    options={[
                      {
                        name: "Yes",
                        value: 0,
                      },
                      {
                        name: "No",
                        value: 1,
                      },
                    ]}
                  />
                  {getAttributes(ATTRIBUTES.PARENTS)?.value === 0 && (
                    <>
                      <div
                        className={classNames(style.twoButtonContainer, "p-3")}
                        id={getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS)?.name}
                      >
                        <p className="primary-color f-700">
                          {
                            getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS)
                              ?.description
                          }
                        </p>
                        <NumberInput
                          half={true}
                          type="number"
                          customWidth={200}
                          hideLabel={true}
                          id={getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS)?.name}
                          value={
                            getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS)?.value
                          }
                          errors={
                            errors?.[
                              getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS)?.name
                            ]
                          }
                          onChange={(e) => {
                            const { value } = e?.target;
                            var num = Number(value);
                            if (value === "" || (num >= 1 && num <= 2)) {
                              handleChange(
                                value === "" ? null : parseInt(value),
                                getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS)
                                  ?.instanceId
                              );
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                  <div style={{ height: "20px" }} />
                  <TwoButtons
                    id="Parents-in-Law"
                    half={true}
                    title={getAttributes(ATTRIBUTES.PARENT_IN_LAW)?.description}
                    errors={errors?.["Parents-in-Law"]}
                    state={getAttributes(ATTRIBUTES.PARENT_IN_LAW)?.value}
                    onClick={(value) => {
                      if (value === 1) {
                        var arr = [...riskAttributes];
                        var index = riskAttributes.findIndex(
                          (x) =>
                            x.instanceId ===
                            getAttributes(ATTRIBUTES.PARENT_IN_LAW)?.instanceId
                        );
                        var index1 = riskAttributes.findIndex(
                          (x) =>
                            x.instanceId ===
                            getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW)
                              ?.instanceId
                        );
                        arr[index] = { ...arr[index], value: value };
                        arr[index1] = { ...arr[index1], value: 0 };
                        return dispatch(updatedAttributes(arr, "UQQ"));
                      }
                      setInputs({ ...inputs, parents: value });
                      handleChange(
                        value,
                        getAttributes(ATTRIBUTES.PARENT_IN_LAW)?.instanceId
                      );
                    }}
                    options={[
                      {
                        name: "Yes",
                        value: 0,
                      },
                      {
                        name: "No",
                        value: 1,
                      },
                    ]}
                  />
                  {getAttributes(ATTRIBUTES.PARENT_IN_LAW)?.value === 0 && (
                    <>
                      <div
                        className={classNames(style.twoButtonContainer, "p-3")}
                        id={
                          getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW)
                            ?.name
                        }
                      >
                        <p className="primary-color f-700">
                          {
                            getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW)
                              ?.description
                          }
                        </p>
                        <NumberInput
                          half={true}
                          type="number"
                          customWidth={200}
                          hideLabel={true}
                          id={
                            getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW)
                              ?.name
                          }
                          value={
                            getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW)
                              ?.value
                          }
                          defaultValue={
                            getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW)
                              ?.value
                          }
                          errors={
                            errors?.[
                              getAttributes(ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW)
                                ?.name
                            ]
                          }
                          onChange={(e) => {
                            const { value } = e?.target;
                            var num = Number(value);
                            if (value === "" || (num >= 1 && num <= 2)) {
                              handleChange(
                                value === "" ? null : parseInt(value),
                                getAttributes(
                                  ATTRIBUTES.NUMBER_OF_PARENTS_IN_LAW
                                )?.instanceId
                              );
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}

            {getCoverAmountInt(
              getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
            ) !== 20000 &&
              getCoverAmountInt(
                getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
              ) !== 30000 &&
              getCoverAmountInt(
                getAttributes(ATTRIBUTES.COVER_AMOUNT)?.value
              ) !== 50000 && (
                <>
                  <div style={{ height: "20px" }} />
                  <TwoButtons
                    id="Extended Family"
                    half={true}
                    title={
                      getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.description
                    }
                    errors={errors?.["Extended Family"]}
                    state={getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value}
                    onClick={(value) => {
                      if (value === 1) {
                        var arr = [...riskAttributes];
                        var index = riskAttributes.findIndex(
                          (x) =>
                            x.instanceId ===
                            getAttributes(ATTRIBUTES.EXTENDED_FAMILY)
                              ?.instanceId
                        );
                        var index1 = riskAttributes.findIndex(
                          (x) =>
                            x.instanceId ===
                            getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)
                              ?.instanceId
                        );
                        arr[index] = { ...arr[index], value: value };
                        arr[index1] = { ...arr[index1], value: 0 };
                        return dispatch(updatedAttributes(arr, "UQQ"));
                      }
                      setInputs({ ...inputs, parents: value });
                      handleChange(
                        value,
                        getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.instanceId
                      );
                    }}
                    options={[
                      {
                        name: "Yes",
                        value: 0,
                      },
                      {
                        name: "No",
                        value: 1,
                      },
                    ]}
                  />
                  {getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === 0 && (
                    <>
                      <div
                        className={classNames(style.twoButtonContainer, "p-3")}
                        id={
                          getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)
                            ?.name
                        }
                      >
                        <p className="primary-color f-700">
                          {
                            getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)
                              ?.description
                          }
                        </p>
                        <NumberInput
                          half={true}
                          type="number"
                          customWidth={200}
                          hideLabel={true}
                          id={
                            getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)
                              ?.name
                          }
                          value={
                            getAttributes(ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY)
                              ?.value
                          }
                          errors={
                            errors?.[
                              getAttributes(
                                ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY
                              )?.name
                            ]
                          }
                          onChange={(e) => {
                            const { value } = e?.target;
                            var num = Number(value);
                            if (value === "" || (num >= 1 && num <= 6)) {
                              handleChange(
                                value === "" ? null : parseInt(value),
                                getAttributes(
                                  ATTRIBUTES.NUMBER_OF_EXTENDED_FAMILY
                                )?.instanceId
                              );
                            }
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
          </>
        )}
      </div>
      <div style={{ height: "20px" }} />
      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium </h5>
          <h5 className="text-white f-700">
            SZL{" "}
            {premiumAmountCalc()
              ? premiumAmountCalc()
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0
              : 0}
          </h5>
        </div>
      </div>
      <NavigationButtons
        back={handleBack}
        loading={isLoading}
        isBackDisabled={isLoading}
        next={() => {
          if (isLoading) {
            return;
          }
          handleSubmit();
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

export default Step2;
