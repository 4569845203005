import React, { useEffect, useState } from "react";

// Form Module css
import style from "./Form.module.css";

// MUI Components
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import classNames from "classnames";
// Importing Forms

// Redux Hooks
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { UpdateSteps} from "../../store/actions/products";
import useWindowDimensions from "../../utils/useWindowDimension";
import Step1 from "./Steps/Step-1";
import Step2 from "./Steps/Step-2";
import Step3 from "./Steps/Step-3";
import Step4 from "./Steps/Step-4";
import { parseQuery, setParam } from "../../utils/utils";

const Form = () => {
  // Dynamic width from Screen sizes
  const { width } = useWindowDimensions();
  const history = useNavigate();
  const location = useLocation();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  // Initial steps or presisted if user resume
  const intitalStep = useSelector((state) => state.products.activeStep);

  const navigate = useNavigate();

  // Active steps according to forms
  const [activeStep, setActiveStep] = useState(intitalStep || 0);

  const riskAttributes = useSelector((state) => state?.products?.riskAttributes);

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);
  
  useEffect(() => {
    if(riskAttributes?.length < 1){
      navigate('/')
    }
  },[riskAttributes])

  // Loader state
  const [isLoading, setIsLoading] = useState(false);

  // Dispatch Hooks
  const dispatch = useDispatch();

  // Function performs Next Step
  const handleNext = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Function performs previous Step
  const handleBack = (step) => {
    
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    
  };

  // Updating Active steps to the store
  useEffect(() => {
  
      dispatch(UpdateSteps(activeStep));
   
  }, [activeStep, dispatch]);

  document.body.style = 'background: #3C409610;';

  const getCompleted = () => {
    switch(activeStep){
      case 0: 
        return 0;
      case 1: 
        return 0;
      case 2: 
        return 1;
      case 3: 
        return 2;
      default:
        return 0;
    }
  }

  const [files, setFiles] = useState({});


  const handleFileUpload = (e) => {
    const { name } = e.target;
    let obj = { ...files };
    if (name) {
      obj = {
        ...files,
        [name]: e.target.files[0],
      };
    }

    setFiles({...obj});
    };

    const handleFormBack = (step) =>{
      setActiveStep(step)
    }



  return !isLoading ? (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        {width > 770 ? (
          <>
            {/* <------- HEADER --------> */}
            <h3 className={style.header}>
              Instant Funeral Cover in a few clicks
            </h3>

            {/* <------- STEPPER STARTS --------> */}

           <Box className="mt-5">
              <Stepper activeStep={getCompleted()} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={index} >
                    <StepLabel completed={getCompleted(index)} >{width > 430 ? label.label : ""}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>


            {/* <------- STEPPER ENDS --------> */}
          </>
        ) : null}

        {/* <------- FORMS STARTS --------> */}

        <div className={classNames("container", style.boxBorder)}>
          {activeStep === 0 && (
            <Step1 handleBack={handleBack} handleNext={handleNext} />
          )}

          {activeStep === 1 && <Step2 handleBack={handleBack} handleNext={handleNext}  />}
          {activeStep === 2 && <Step3 handleBack={handleBack} handleNext={handleNext} files={files} handleFileUpload={handleFileUpload}  />}
          {activeStep === 3 && <Step4 handleBack={handleBack} handleNext={handleNext} files={files} handleFormBack={handleFormBack} />}
        </div>
        {/* <------- FORMS ENDS --------> */}
      </div>
    </div>
  ) : null;
};

// const steps = [
//   {
//     label: "Select Cover"
//   },
//   {
//     label: "Complete details"
//   },
//   {
//     label: "Complete Personal Details"
//   },
// ];


const steps = [
  {
    label: "Select Cover"
  },
  {
    label: "Complete Personal Details"
  },
  {
    label: "Application & payment details"
  },
];

export default Form;

